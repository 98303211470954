import ReactPlayer from "react-player/lazy"
import React, { Component } from 'react'

class ContactForm extends Component {

    constructor(props) {
        super(props)
        const form = props.forms.nodes.find(node => node.slug === 'education');
        this.state = {
            apiURL: form.apiURL,
            formFields: form.formFields,
            submitButton: form.button,
            canSubmit: true,
            message: '',
            status: null,
            isSubmitting: false,
            successMessage: form.confirmations[0].message,
            isVerified: false
        }
    }

    handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        const element = this.state.formFields.find(field => field.id === +name);
        element.content = value;
        this.setState({
            formFields: [
                ...this.state.formFields,
            ]
        })
    }

    componentDidMount() {
        // if (recaptchaRef) {
        //     recaptchaRef.current.execute();
        // }
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        // recaptchaRef.current.execute();
        // if (this.state.canSubmit === false) {
        //     return false
        // }
        // if (this.state.isVerified === true) {
            this.setState({ isSubmitting: true });
            ev.preventDefault();
            const form = ev.target
            const data = this.state.formFields.reduce((acc, field) => (acc[field.id.toString()] = field.content, acc), {});
            const xhr = new XMLHttpRequest()
            xhr.open(form.method, form.action)
            xhr.setRequestHeader('Accept', 'application/json')
            xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(`${process.env.GRAVITYFORM_KEY}:${process.env.GRAVITYFORM_SECRET}`))
            xhr.onreadystatechange = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) return;
                if (xhr.status === 201) {
                    this.resetForm();
                    this.setState({ message: this.state.successMessage, status: xhr.status });
                } else {
                    this.setState({ message: 'There was an error', status: xhr.status });
                }
                this.setState({ isSubmitting: false });
            }
            xhr.send(JSON.stringify(data))
        // } else {
        //     this.setState({ message: 'You are not a human.' });
        // }
    }

    resetForm = () => {
        this.setState({
            formFields: this.state.formFields.map(field => {
                field.content = ''
                return field
            })
        })
    }

    handleVerify = (response) => {
        if (response) {
            this.setState({ isVerified: true });
        }
    }

    render() {
        return (
            <form method="post" action={this.state.apiURL + '/entries'} onSubmit={this.handleSubmit}>
                {this.state.formFields ? this.state.formFields.map(field => {
                    switch(field.type) {
                        case 'text':
                            return (
                                <div className="form-field" key={field.id}>
                                    <input name={field.id} type="text" placeholder={field.placeholder} required={field.isRequired} aria-label={field.placeholder} onChange={this.handleChange} />
                                </div>
                            )
                        case 'email':
                            return (
                                <div className="form-field" key={field.id}>
                                    <input name={field.id} type="email" placeholder={field.placeholder} required={field.isRequired} aria-label={field.placeholder} onChange={this.handleChange} />
                                </div>
                            )
                        case 'textarea':
                            return (
                                <div className="form-field" key={field.id}>
                                    <textarea name={field.id} rows="7" placeholder={field.placeholder} required={field.isRequired} aria-label={field.placeholder} onChange={this.handleChange}></textarea>
                                </div>
                            )
                        case 'select':
                            const choices = JSON.parse(field.choices);
                            return (
                                <div className="form-field" key={field.id}>
                                    <select name={field.id} onChange={this.handleChange} aria-label={field.placeholder}>
                                        <option>{field.placeholder}</option>
                                        {choices ? choices.map((choice, index) => {
                                            return (
                                                <option key={'choice-' + index} value={choice.value}>{choice.text}</option>
                                            )
                                        }) : ''}
                                    </select>
                                </div>
                            )
                    }
                }) : ''}
                <div className="button-container">
                    {/* <ReCAPTCHA
                        sitekey="6Lc16v4ZAAAAAHcBu8iaoVSx1RbTbpCT1FonJ101"
                        onChange={this.handleVerify}
                        ref={recaptchaRef}
                        theme="dark"
                        size="invisible"
                    /> */}
                    <button className="button button-light" type="submit" disabled={this.state.isSubmitting}>
                        {this.state.submitButton.text}
                    </button>
                </div>
                <div className="response">
                    {this.state.message}
                </div>
            </form>
        )
    }
}

const Education = ({ fields, forms }) => {
  return (
<div>
		<div className="row-ban-ed" style={{backgroundImage: `url(${fields?.banner.sourceUrl})`}}>

		<div className="intro-row">
			<div className="intro-col1">
								<div className="logo">
					<img className="image"
						src={fields.logo.sourceUrl}
						alt={fields.logo.alttext}
					/> 	
				</div>
			</div>
			<div className="intro-col-line show-desktop"></div>
			<div className="intro-col2">
				<div
					dangerouslySetInnerHTML={{
						__html: fields.intro,
					}}
				></div>
			</div>
			
		</div>
		<div className="title-row">
			<h6>{fields.title}</h6>
		</div>
		</div>



		
		<div className="ed-form-row">
			<div className="col-form">
				<div className="example">
					<img className="image"
						src={fields.example.sourceUrl}
						alt={fields.example.alttext}
					/> 	
				</div>
			</div>
						
			<div className="col-form">
				<ContactForm forms={forms} />
			</div>
		</div>
</div>
	 
  );
}

export default Education
