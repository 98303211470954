import React from "react"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player/lazy"
import Gallery from "./Gallery"
import Popup from "./Popup"

const Home = ({ fields, options }) => {
  const popup = {
    show: options.showPopup,
    video: options.video,
    content: options.content,
    title: options.socialTitle,
    social: options.social,
  }

  return (
    <div className="home-wrap">
	<div className="show-mobile">
            <div className="video-background">
              <ReactPlayer
                url="https://api.visualforester.com/wp-content/uploads/2022/10/Stand_Designer__Roadtrip_banner_fix-360.mp4"
				playsinline={true}
                className="react-player"
                width="100vw"
                height="auto"
                playing={true}
                loop={true}
                volume={0}
                muted={true}
              />
            </div>
	</div>
      {fields.bannermain ? (
	  
        <div  className="home-banner">
          <div className="content">
            <h1>
              {fields.bannermain.title}
              <strong>{fields.bannermain.subtitle}</strong>
            </h1>
            <div className="button-wrap">
              <a href="#services" className="button button-light">
                {fields.bannermain.button}
              </a>
            </div>
          </div>
		  		  <div className="show-desktop">
            <div className="video-background">
              <ReactPlayer
                url="https://api.visualforester.com/wp-content/uploads/2022/10/Stand_Designer__Roadtrip_banner_fix-720.mp4"
                className="react-player"
                width="100%"
                height="auto"
                playing={true}
                loop={true}
                volume={0}
                muted={true}
              />
            </div>
		 </div>
		  
        </div>
      ) : (
        ""
      )}
	  
	  {fields.text ? (
        <section className="home-text">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="multi-title">
                  <span>{fields.text.pretitle}</span>
                  <h2>
                    {fields.text.title}
                    <strong>{fields.text.subtitle}</strong>
                  </h2>
                </div>
              </div>
              <div className="col">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: fields.text.content }}
                ></div>
                <div className="button-wrap">
                  <a href="#services" className="button">
                    {fields.text.button}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {fields.gallery ? (
        <section className="home-gallery">
          <div className="container">
            <div className="row">
              <div className="col">
                <Gallery items={fields.gallery.gallery} />
              </div>
              <div className="col">
                <div className="multi-title">
                  <h2>
                    {fields.gallery.title}
                    <strong>{fields.gallery.subtitle}</strong>
                  </h2>
                </div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: fields.gallery.content }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
	  
  {fields.featuresText ? (
  <div>
        <section className="home-text-features show-desktop">
		<div className="container-home">
			
            <div className="row-main">
              <div className="col-main">
                <h2><br></br>{fields.featuresText.title}<br></br>&nbsp;</h2>
              </div>
			  <div className="col-sub">
			  <div className="row-fill"> </div>
				<div className="row-sub">
					<div className="col-button">
						<a
							href={fields.featuresText.buttonUrl2}
							className="button button-light"
						>
							{fields.featuresText.button2}
						</a>
					</div>
						<div className="col">
								<div
								className="content"
								dangerouslySetInnerHTML={{
								__html: fields.featuresText.content,
								}}
								></div>
						</div>
              </div>
			  <div className="row-sub">
			  <div className="row-fill"> </div>
					<div className="col-button">
					</div>
						<div className="col">
								<div
								className="content"
								dangerouslySetInnerHTML={{
								__html: fields.featuresText.content2,
								}}
								></div>
						</div>
              </div>
			  </div>
            </div>
          </div>
        </section>
		 </div>
      ) : (
        ""
      )}	  
	 
<a name = "services">	  
      {fields.servicetable ? (	  
<div className="service-table">
	<div className="show-desktop">
	<div className="row">
		<div className="col">
            <h2>{fields.servicetable.service1.title}</h2>                    
			<div className="logo">
				<img
					src={fields.servicetable.service1.logo.sourceUrl}
					alt={fields.servicetable.service1.logo.alttext}
					width= "200px"
				/> 	
			</div>
			<div
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service1.description,
             }}
            ></div>

		</div>
		
		<div className="col-line show-desktop"></div>
		
		<div className="col">
            <h2>{fields.servicetable.service2.title}</h2>                    
			<div className="logo">
				<img
					src={fields.servicetable.service2.logo.sourceUrl}
					alt={fields.servicetable.service2.logo.alttext}
					width= "200px"
				/> 	
			</div>
			<div
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service2.description,
             }}
            ></div>

		</div>
		
		<div className="col-line show-desktop"></div>
		
		<div className="col">
             <h2>{fields.servicetable.service3.title}</h2>                    
			<div className="logo">
				<img
					src={fields.servicetable.service3.logo.sourceUrl}
					alt={fields.servicetable.service3.logo.alttext}
					width= "200px"
				/> 	
			</div>
			<div
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service3.description,
             }}
            ></div>
						
		</div>
	</div>
	
	<div className="row-asp">
		<div className="col">
			<h1>Features</h1>
			<div className="aspects"
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service1.aspects,
             }}
            ></div>
		</div>
			
		<div className="col-line show-desktop"></div>
		
		<div className="col">
			<h1>Features</h1>
			<div className="aspects"
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service2.aspects,
             }}
            ></div>
		</div>
		
		<div className="col-line show-desktop"></div>
		
		<div className="col">
					 <div className="video-wrapper">
                  {fields.services.video ? (
                    <ReactPlayer
                      url={fields.services.video}
                      className="react-player"
                      width="100%"
                      height="300px"
                      loop={false}
                      controls={true}
                    />
                  ) : (
                    ""
                  )}
                </div>
				<br></br>
		</div>
			
	</div>
	
	<div className="row-but">
		<div className="col">
			<div className="button">
				<a href={fields.servicetable.service1.buttonurl}>
					{fields.servicetable.service1.button}
				</a>
			</div>	
		</div>
		
		<div className="col-line show-desktop"></div>
		
			<div className="col">
			<div className="button">
				<a href={fields.servicetable.service2.buttonurl}>
					{fields.servicetable.service2.button}
				</a>
			</div>	
		</div>
		
		<div className="col-line show-desktop"></div>
		
		<div className="col">
<div className="button">
				<a href={fields.servicetable.service3.buttonurl}>
					{fields.servicetable.service3.button}
				</a>
			</div>	
		</div>
	
	</div>
	
</div>
	
	
	<div className="show-mobile">
		
		<div className="col">
            <h2>{fields.servicetable.service1.title}</h2>                    
			<div className="logo">
				<img
					src={fields.servicetable.service1.logo.sourceUrl}
					alt={fields.servicetable.service1.logo.alttext}
					width= "200px"
				/> 	
			</div>
			<div
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service1.description,
             }}
            ></div>
			<div className="aspects"
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service1.aspects,
             }}
            ></div>
			<div className="button">
                <a
                    href={fields.servicetable.service1.buttonurl}
                >
					{fields.servicetable.service1.button}
                 </a>
            </div>	
		</div>
		
		<div className="col-line-mobile"></div>
		
		<div className="col">
            <h2>{fields.servicetable.service2.title}</h2>                    
			<div className="logo">
				<img
					src={fields.servicetable.service2.logo.sourceUrl}
					alt={fields.servicetable.service2.logo.alttext}
					width= "200px"
				/> 	
			</div>
			<div
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service2.description,
             }}
            ></div>
			<div className="aspects"
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service2.aspects,
             }}
            ></div>
			<div className="button">
                <a
                    href={fields.servicetable.service2.buttonurl}
                >
					{fields.servicetable.service2.button}
                 </a>
            </div>	
		</div>
		
		<div className="col-line-mobile"></div>
		
		<div className="col">
             <h2>{fields.servicetable.service3.title}</h2>                    
			<div className="logo">
				<img
					src={fields.servicetable.service3.logo.sourceUrl}
					alt={fields.servicetable.service3.logo.alttext}
					width= "200px"
				/> 	
			</div>
			<div
            dangerouslySetInnerHTML={{
				__html: fields.servicetable.service3.description,
             }}
            ></div>
						 <div className="video-wrapper">
                  {fields.services.video ? (
                    <ReactPlayer
                      url={fields.services.video}
                      className="react-player"
                      width="100%"
                      height="200px"
                      loop={false}
                      controls={true}
                    />
                  ) : (
                    ""
                  )}
                </div>
				<br></br>
			
			<div className="button">
                <a
                    href={fields.servicetable.service3.buttonurl}
                >
					{fields.servicetable.service3.button}
                 </a>
            </div>	
		</div>
	</div>
 </div>
 
       ) : (
        ""
      )}
</a>
    	  {fields.featuresText ? (
			<div>	
		<section className="home-text-features show-mobile">
		        <div className="container">
            <div className="row">
              <div className="col-mobile">
                <h1><strong>{fields.featuresText.title}</strong></h1>
				
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: fields.featuresText.content,
                  }}
                ></div>
                
				  
				 <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: fields.featuresText.content2,
                  }}
                ></div>

                  <a
                    href={fields.featuresText.buttonUrl2}
                    className="button button-light"
                  >
                    {fields.featuresText.button2}
                  </a>

              </div>
            </div>
          </div>
		 </section> 
		 </div>
      ) : (
        ""
      )}	  




      <Popup data={popup} />
    </div>
  )
}

export default Home
