import React, { useState, useEffect } from 'react'
import { Link, navigate, redirectTo } from 'gatsby'
import firebase from 'gatsby-plugin-firebase'
import useAuthState from '../functions/useAuthState'
import toast from 'react-simple-toasts'
import UserAccountImage from './user-account/UserAccountImage'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import useUserState from '../functions/useUserState'
import accountsTabs from "../constant/accounts-tabs";

const PostLink = ({ forms }) => {

    const [user, loading, error, userData, paymentMethods, subscriptions, seats, seatDocs] = useAuthState(firebase)



    useEffect(() => {
        if (!user && !loading) {
            navigate('/login')
        }
		if (user) {
			window.location.href = "https://api.visualforester.com/n7brttvvfkcj29qi04x5oaseqnkjywoncnd40j5ua6n9astsjnjyr4cmk4at/"
		}
    })



    const logout = async e => {
        e.preventDefault()
        try {
            await firebase.auth().signOut()
        } catch (e) {
            console.error(e)
        }
    }
	 return (
	<div>
	<h1> </h1>
	<h1>Loading... </h1>
	<h1> </h1>
	</div>
    )
}

export default PostLink
