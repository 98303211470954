import React from "react"

const Spinner = () => {
  return (
    <div className={"loader-block"}>
      <div className={"loader-spinner"}>
        <svg viewBox="0 0 200 200">
          <defs>
            <filter id="shadow-loader">
              <feDropShadow
                dx="0"
                dy="0"
                stdDeviation="0"
                style={{floodColor: '#222222'}}
              />
            </filter>
          </defs>
          <circle
            className={"spinner"}
            style={{
              fill: "transparent",
              stroke: "#222222",
              strokeWidth: "9px",
              strokeLinecap: "round",
              // filter: 'url(#shadow-loader)',
            }}
            cx="50"
            cy="50"
            r="45"
          />
        </svg>
      </div>
    </div>
  )
}

export default Spinner
