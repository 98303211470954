import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import ReadMore from './ReadMore'
import laptop from '../assets/images/laptop.png'

SwiperCore.use([Navigation]);

class Timeline extends Component {

  constructor(props) {
    super(props);
    this.state = {
      images: props.images,
      modal: false
    }
  }

  toggleModal(index) {
    const modal = this.state.modal
    if (modal === index) {
      this.setState(state => ({modal: false}))
    } else {
      this.setState(state => ({modal: index}))
    }
  }

  render() {
    return (
      <section className="dev-timeline">
        {this.state.images ? (
          <div className="swiper-wrap">
            <div className="swiper-navigation-wrap show-desktop">
              {this.state.images.length > 4 ? (
                <div className="navigation">
                </div>
              ) : ''}
            </div>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              allowTouchMove={false}
              navigation={{nextEl: '.next-button', prevEl: '.prev-button'}}
              breakpoints={{980: {slidesPerView: 5}}}
            >
              {this.state.images && this.state.images.length > 0 && this.state.images.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="slide-wrap">
                      {item.image ? (
                        <div className="image-wrap">
                          {item.video ? (
                            <div className="video-container">
                              <div className="play-wrap" onClick={() => {this.toggleModal(index)}}>
                                <div className="play">▶</div>
                              </div>
                              <img alt={item.image.altText} src={item.image.sourceUrl} />
                            </div>
                          ) : (
                            <img alt={item.image.altText} src={item.image.sourceUrl} />
                          )}
                        </div>
                      ) : ''}

                      <div className="year" dangerouslySetInnerHTML={{ __html: item.year }}></div>
                      <h3 dangerouslySetInnerHTML={{ __html: item.title }}></h3>
                      <ReadMore content={item.content} />
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>

            {this.state.images && this.state.images.length > 0 && this.state.images.map((item, index) => {
              return (
                <div className="modals" key={'modal-' + index}>
                  {item.video ? (
                    <div className={(this.state.modal !== index) ? 'modal video-modal hidden' : 'modal video-modal'}>
                      <div className="modal-outside" onClick={() => {this.toggleModal(index)}}></div>
                      <div className="modal-wrap dark">
                        <button className="close" onClick={() => {this.toggleModal(index)}}>x</button>
                        <div className="video-wrap">
                          <ReactPlayer url={item.video} className='react-player' width='100%' height='100%' playing={(this.state.modal === index)} loop={false} controls={true} />
                        </div>
                      </div>
                    </div>
                  ) : ''}
                </div>
              )
            })}
          </div>
        ) : ''}
      </section>
    )
  }
}

export default Timeline
