import React, { Component, setState } from 'react'
import Truncate from 'react-truncate';
import downArrow from '../assets/svg/down-arrow.svg'

class ReadMore extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: props.content,
            expanded: false,
            truncated: false,
        }

        this.handleTruncate = this.handleTruncate.bind(this);
        this.toggleLines = this.toggleLines.bind(this);
    }

    handleTruncate(truncated) {
        if (this.state.truncated !== truncated) {
            this.setState({
                truncated
            });
        }
    }

    toggleLines(event) {
        event.preventDefault();

        this.setState({
            expanded: !this.state.expanded
        });
    }

    render() {
        const lines = 6;
        const { content, expanded, truncated } = this.state;

        return (
            <div className="read-more-container">
                <div className="read-more-content">
                    <Truncate
                        lines={!expanded && lines}
                        ellipsis={
                          <span>
                            ...
                            <a href="#" className="read-more-toggle" onClick={this.toggleLines}>
                                Read More <img src={downArrow} />
                            </a>
                          </span>
                        }
                        onTruncate={this.handleTruncate}
                    >
                        <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </Truncate>
                </div>
                {!truncated && expanded && (
                    <span>
                        <a href="#" className="read-more-toggle open" onClick={this.toggleLines}>
                            Read Less <img src={downArrow} />
                        </a>
                    </span>
                )}
            </div>
        )
    }

}

export default ReadMore
