import React, { useEffect, useState } from 'react'
import firebase from 'gatsby-plugin-firebase'
import useAuthState from '../functions/useAuthState'
import StandDesignerIcon from '../assets/svg/stand-designer-icon.svg'

const OrderPlaced = () => {

  const [user, loading, error, userData, paymentMethods] = useAuthState(firebase)
  const [subscription, setSubscription] = useState(null)
  const [seatId, setSeatId] = useState(null)
  const [seats, setSeats] = useState(null)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (!user) return
    if (isBrowser) {
      const urlParams = new URLSearchParams(window?.location?.search);
      const orderId = urlParams?.get('order_id');

      if (window?.gtag) {
        window.gtag('event', 'conversion', { 'send_to': 'AW-408783229/bEXXCIagrvsBEP2S9sIB', 'transaction_id': orderId });
        window.gtag('event', 'conversion', { 'send_to': 'AW-408783229/4sYnCJuisPsBEP2S9sIB' });
      }

      const orderDoc = firebase.firestore()
        .collection('Users')
        .doc(user.uid)
        .collection('payments')
        .where('id', '==', orderId).get()
        .then(querySnapshot => {
          if (querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // console.log(doc.data())
              setSeatId(doc.id)
              setSubscription(doc.data())
            });
          }
        })
    }
  }, [user])

  useEffect(() => {
    if (!seatId) return
    firebase.firestore()
        .collection('seats')
        .doc(seatId)
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            const data = snapshot.data();
            setSeats(data.seats);
          }
        })
  }, [seatId])


  return (
    <React.Fragment>

      {subscription ? (
        <section className="order-placed">
          <div className="container">
            <div className="row">
              <div className="order-placed-section">
                <h4>Order Placed</h4>
                <div className="row order-number">
                  <h5><span>Order #</span> <span>{subscription.id}</span></h5>
                </div>
                <div className="row order-details">
                  <span>Date</span>
                  <span className="product">{new Date(subscription.created * 1000).toLocaleDateString('en-US')}</span>
                </div>
                <div className="row order-details">
                  <span>Application</span>
                  <span className="product">Visual Forester Stand Designer</span>
                </div>
                <div className="row order-details">
                  <span>Commitment</span>
                  <div className="product">
                    <span>{subscription.plan.interval === 'year' ? 'Annual' : 'Monthly'} Plan</span>
                    <span className="product-details">Expires {new Date(subscription.current_period_end * 1000).toLocaleDateString('en-US')}</span>
                    <span className="product-details">Auto-Renewal</span>
                  </div>
                </div>
                <div className="row order-details">
                  <span>Number of Seats</span>
                  <span className="product">{subscription.quantity}</span>
                </div>
                <div className="row order-details">
                  <span>Subtotal</span>
                  <div className="cart-details">
                    <span>${(subscription.subtotal).toFixed(2)}</span>
                  </div>
                </div>
                <div className="row order-details">
                  <span>Discount</span>
                  <div className="cart-details">
                    <span>${subscription.couponTotal ? '-' + (subscription.couponTotal).toFixed(2) : '0.00'}</span>
                  </div>
                </div>
                <div className="row order-details">
                  <span>Estimated Tax</span>
                  <div className="cart-details">
                    <span>${subscription.tax_total ? (subscription.tax_total).toFixed(2) : '0.00'}</span>
                  </div>
                </div>
                <hr className="order-sum"/>
                <div className="row order-details">
                  <span className="cart-details">Total</span>
                  <div className="cart-details">
                    <span>$<span class="total-sum">{(subscription.total).toFixed(2)}</span></span>
                  </div>
                </div>
                {/* <div className="button-wrapper">
                  <button className="button button-dark">Download Invoice</button>
                </div> */}
              </div>
              <div className="product-details-section">
                <div className="row">
                  <div className="col">
                    <div className="image-container">
                      <div className="image-wrapper">
                        <img src={StandDesignerIcon}/>
                        <span>Stand Designer</span>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="product-button-wrapper">
                      <a className="button button-dark" href="https://firebasestorage.googleapis.com/v0/b/vf-stand-designer.appspot.com/o/Setup%2FStand%20Designer%20Setup.exe?alt=media&token=669afe55-2b03-46d9-ab4e-19858a65b8a0">Download</a>
                    </div>
                  </div>
                </div>
                <div className="license-fields">
                  {seats && seats.map(seat => {
                    return (
                      <div className="row" key={seat.seatId}>
                        <div className="col license-key-field">
                          <p>Seat ID:</p>
                          <div className="license-key-num">
                            <span>{seat.seatId}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="row order-note">
                  <p>Check your email for order confirmation and user details.</p>
                </div>
                <hr />
                <div className="row">
                  <div className="col product-installation">
                    <p>Installation</p>
                    <div className="row product-installation-steps">
                      <ol>
                        <li>Click “Download” to download Stand Designer Setup.exe.</li>
                        <li>Run Stand Designer Setup.exe, then follow the instructions on-screen to install Stand Designer.</li>
                        <li>Open Stand Designer. Accept the agreement, then copy/paste a seat ID that you have purchased (see list above). You can also access your seat IDs on the “Manage Seats” tab.</li>
                        <li>Sign in with any visualforester.com account.</li>
                        <li>On the Welcome Menu, click “Tutorials” to learn how to get started.</li>
                      </ol>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <p>Order not found</p>
      )}


    </React.Fragment>
  )
}

export default OrderPlaced
