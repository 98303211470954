import React, { useEffect, useState } from "react"
import UploadFiles from "./UploadFiles"
import ViewComparisons from "./ViewComparisons"
import Spinner from "../../Spinner"
import ArrowSvg from '../../../assets/images/arrow.svg'
import {
  downloadAllImageUser,
  getMetaData,
  uploadImage,
  removeImage,
} from "../../../service/fire-base-image"

const formatDate = () => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  const today = new Date()
  return today.toLocaleDateString("en-US", options)
}
const EditImage = ({ user, editId, editFolder }) => {
  const [initState, setInitState] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!user?.uid) return
    const metaData = getMetaData(`${user?.uid}/${editId}/metadata`)
    const urls = downloadAllImageUser(`${user?.uid}/${editId}`)
    Promise.all([metaData, urls]).then((results) => {
      let [metaDate, links] = results

      results[1].sort((a, b) => (a.order > b.order) ? 1 : -1)

      setInitState({
        files: links,
        metaData: metaDate?.customMetadata,
      })
      setLoading(false)
    })
  }, [user])

  const removeImageFireBase = async (url) => {
    setLoading(true)
    await removeImage(url)
    setLoading(false)
  }

  const publishFiles = async ({ files, metaData }) => {
    setLoading(true)
    // files = files.filter((file) => {
    //   return file instanceof File
    // })
    metaData = { ...metaData, date: formatDate() }

    await uploadImage(files, `${user.uid}/${editId}`, metaData)
    setLoading(false)
    editFolder(null)
  }
  if (initState === null) return <Spinner />
  return (
    <div>
      {loading ? <Spinner /> : null}
      <span
        className={"arrow-back"}
        onClick={() => {
          editFolder(null)
        }}
      >
        <img src={ArrowSvg} alt=""/>
      </span>
      <UploadFiles
        publishFiles={publishFiles}
        initState={initState}
        removeImageFireBase={removeImageFireBase}
      >
        Publish
      </UploadFiles>
      <ViewComparisons folder={editId} />
    </div>
  )
}
export default EditImage
