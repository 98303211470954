import React, { useState, useContext, useEffect } from 'react'
import GoogleLogo from '../assets/svg/google_logo.svg'
import AppleLogo from '../assets/svg/apple_logo.svg'
import firebase from 'gatsby-plugin-firebase'
import useAuthState from '../functions/useAuthState'
// import { AuthContext } from '../context/auth'
import { navigate, Link } from 'gatsby'
import toast from 'react-simple-toasts'

const ForgotPassword = () => {
  const [state, setState] = useState({
    email: '',
    error: null
  })

  const [user, loading, error] = useAuthState(firebase)

  const handleChange = e => {
    const value = e.target.value
    setState({ 
      ...state,
      [e.target.name]: value
    })
  }
  
  const handleSubmit = async (e) => {
      e.preventDefault()
    try {
        await firebase.auth().sendPasswordResetEmail(state.email)
        toast('Password reset email has been sent.', 4000)
        navigate('/login')
    } catch (err) {
        console.error(err)
    }
  }


  return (
    <React.Fragment>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="login-section">
              <div className="login-heading">
                <h4>Forgot password?</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="cart-form-field">
                  <input
                    name="email" 
                    type="email" 
                    placeholder="Email*" 
                    value={state.email} 
                    onChange={handleChange}
                  />
                </div>
                <div className="signin-container">
                  <button className="button button-dark">Reset Password</button>
                </div>
                {state.error ? <p>{state.error}</p> :  null}
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ForgotPassword