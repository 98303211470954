import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import ReCAPTCHA from 'react-google-recaptcha'
import NewsletterSignUp from './NewsletterSignUp'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

// const recaptchaRef = React.createRef();
class ContactForm extends Component {
    constructor(props) {
        super(props)
        const form = props.forms.nodes.find(node => node.slug === 'contact');
        // console.log(form, 'form')
        this.state = {
            apiURL: 'https://api.visualforester.com/wp-json/gf/v2/forms/1/submissions',
            formFields: form.formFields,
            submitButton: form.button,
            canSubmit: true,
            message: '',
            status: null,
            isSubmitting: false,
            successMessage: form.confirmations[0].message,
            isVerified: false
        }

        this.phoneInputRef = React.createRef(null);
    }

    // componentDidMount() {
    //   if (this.phoneInputRef.current) {
    //     // window.intlTelInput(this.phoneInputRef.current, {
    //     //   separateDialCode: true
    //     // });
    //     console.log(this.phoneInputRef.current)
    //     this.phoneInputRef.current.addEventListener("countrychange", function() {
    //       console.log('country change?')
    //     });
    //   }
    // }

    handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        const element = this.state.formFields.find(field => field.id === +name);
        element.content = value;
        this.setState({
            formFields: [
                ...this.state.formFields,
            ]
        })
        console.log(this.state)
    }

    componentDidMount() {
        // if (recaptchaRef) {
        //     recaptchaRef.current.execute();
        // }
    }

    handleSubmit = (ev) => {
        ev.preventDefault();

        // recaptchaRef.current.execute();
        // if (this.state.canSubmit === false) {
        //     return false
        // }
        // if (this.state.isVerified === true) {
            this.setState({ isSubmitting: true });
            ev.preventDefault();
            const form = ev.target
            const data = this.state.formFields.reduce((acc, field) => (acc[field.id.toString()] = field.content, acc), {});

            let newData = {}

            Object.entries(data).forEach(([key, value]) => {
              newData['input_' + key] = value;
            })

            const xhr = new XMLHttpRequest()
            xhr.open(form.method, form.action)
            xhr.setRequestHeader('Accept', 'application/json')
            xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(`${process.env.GRAVITYFORM_KEY}:${process.env.GRAVITYFORM_SECRET}`))
            xhr.onreadystatechange = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) return;
                if (xhr.status === 200) {
                    this.resetForm();
                    this.setState({ message: this.state.successMessage, status: xhr.status });
                } else {
                    this.setState({ message: 'There was an error', status: xhr.status });
                }
                this.setState({ isSubmitting: false });
            }
            xhr.send(JSON.stringify(newData))
        // } else {
        //     this.setState({ message: 'You are not a human.' });
        // }
    }

    resetForm = () => {
        this.setState({
            formFields: this.state.formFields.map(field => {
                field.content = ''
                return field
            })
        })
    }

    handleVerify = (response) => {
        if (response) {
            this.setState({ isVerified: true });
        }
    }

    render() {
        return (
            <form method="post" action={this.state.apiURL} onSubmit={this.handleSubmit}>
                {this.state.formFields ? this.state.formFields.map(field => {
                    switch(field.type) {
                        case 'text':
                            return (
                                <div className="form-field" key={field.id}>
                                    <input name={field.id} type="text" placeholder={field.placeholder} required={field.isRequired} aria-label={field.placeholder} onChange={this.handleChange} />
                                </div>
                            )
                        case 'email':
                            return (
                                <div className="form-field" key={field.id}>
                                    <input name={field.id} type="email" placeholder={field.placeholder} required={field.isRequired} aria-label={field.placeholder} onChange={this.handleChange} />
                                </div>
                            )
                        case 'phone':
                            return (
                                <div className="form-field" key={field.id}>
                                    <IntlTelInput
                                      inputClassName="form-control"
                                      containerClassName="intl-tel-input"
                                      fieldId="phone"
                                      fieldName={field.id}
                                      ref={this.phoneInputRef}
                                      initialCountry="auto"
                                      fieldPlaceholder={field.placeholder}
                                      separateDialCode
                                      onPhoneNumberChange={(isValid, phoneNumber, countryData, fullNumber, extension) => {
                                        const e = {
                                            target: {
                                                name: field.id,
                                                value: fullNumber,
                                            }
                                        }

                                        this.handleChange(e)
                                      } }
                                      onSelectFlag={(currentNumber, seletedCountryData, fullNumber, isValid) => {
                                        const e = {
                                            target: {
                                                name: field.id,
                                                value: fullNumber,
                                            }
                                        }

                                        this.handleChange(e)
                                      }}
                                    />
                                </div>
                            )
                        case 'textarea':
                            return (
                                <div className="form-field" key={field.id}>
                                    <textarea name={field.id} rows="7" placeholder={field.placeholder} required={field.isRequired} aria-label={field.placeholder} onChange={this.handleChange}></textarea>
                                </div>
                            )
                        case 'select':
                            const choices = JSON.parse(field.choices);
                            return (
                                <div className="form-field" key={field.id}>
                                    <select name={field.id} onChange={this.handleChange} aria-label={field.placeholder}>
                                        <option>{field.placeholder}</option>
                                        {choices ? choices.map((choice, index) => {
                                            return (
                                                <option key={'choice-' + index} value={choice.value}>{choice.text}</option>
                                            )
                                        }) : ''}
                                    </select>
                                </div>
                            )
                    }
                }) : ''}
                <div className="button-container">
                    {/* <ReCAPTCHA
                        sitekey="6Lc16v4ZAAAAAHcBu8iaoVSx1RbTbpCT1FonJ101"
                        onChange={this.handleVerify}
                        ref={recaptchaRef}
                        theme="dark"
                        size="invisible"
                    /> */}
                    <button className="button button-light" type="submit" disabled={this.state.isSubmitting}>
                        {this.state.submitButton.text}
                    </button>
                </div>
                <div className="response">
                    {this.state.message}
                </div>
            </form>
        )
    }
}

const Marker = () => {
    return (
        <div>
            <div className="marker"></div>
            <div className="pulse"></div>
        </div>
    )
}

const Contact = ({ fields, forms }) => {
    const center = {
        lat: 33.9760286,
        lng: -84.2160764
    }
    const zoom = 9
    const { form, locations, locationContent } = fields;
    return (
        <React.Fragment>
            {form ? (
                <div className="contact-form">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="heading">
                                    <span className="subtitle">Contact</span>
                                    <h2 dangerouslySetInnerHTML={{ __html: form.title }}></h2>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: form.content }}></div>
                                </div>
                                <div className="newsletter show-desktop">
                                    <h2 dangerouslySetInnerHTML={{ __html: form.newsletterTitle }}></h2>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: form.newsletterContent }}></div>
                                    <NewsletterSignUp color="light" />
                                </div>
                            </div>
                            <div className="col">
                                <ContactForm forms={forms} />
                            </div>
                            <div className="col show-mobile">
                                <div className="newsletter show-mobile">
                                    <h2 dangerouslySetInnerHTML={{ __html: form.newsletterTitle }}></h2>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: form.newsletterContent }}></div>
                                    <NewsletterSignUp color="light" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''}
            <div className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col logo">
                            <img src={locationContent.logo.sourceUrl} alt={locationContent.logo.altText} />
                            <div dangerouslySetInnerHTML={{ __html: locationContent.content }}></div>
                        </div>
                        {locations ? (
                            locations.map((location, index) => {
                                return (
                                    <div className="col map" key={`location-${index}`}>
                                        <div className="content">
                                            <h3>{location.title}</h3>

                                            {location.location ? (
                                                <div className="location-details">
                                                    <p>
                                                        {location.location.streetNumber} {location.location.streetName}
                                                    </p>
                                                    <p>
                                                        {location.location.city}, {location.location.stateShort} {location.location.postCode}
                                                    </p>
                                                </div>
                                            ) : ''}

                                            <p>
                                                <a href={'tel:' + location.phone}>
                                                    {location.phone}
                                                </a>
                                            </p>
                                        </div>

                                        {location.location ? (
                                            <div className="map-container">
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key: 'AIzaSyCqMM8hezthjrR12fDQwfjOWDRs8y_rP24' }}
                                                    defaultCenter={{
                                                        lat: location.location.latitude,
                                                        lng: location.location.longitude
                                                    }}
                                                    defaultZoom={zoom}>
                                                        <Marker lat={location.location.latitude} lng={location.location.longitude}/>
                                                </GoogleMapReact>
                                            </div>
                                        ) : ''}
                                    </div>
                                )
                            })
                        ) : ''}

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Contact
