import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import firebase from 'gatsby-plugin-firebase'
import useAuthState from '../functions/useAuthState'
import toast from 'react-simple-toasts'
import UserAccountImage from './user-account/UserAccountImage'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import useUserState from '../functions/useUserState'
import accountsTabs from "../constant/accounts-tabs";

const MyAccountTabs = ({user, userData, paymentMethods, subscriptions, seats, seatDocs, form}) => {

    const [activeTab, setActiveTab] = useState(0)
    const [activeSubscription, setActiveSubscription] = useState(null)

    const changeTab = (index) => {
      setActiveSubscription(null)
      setActiveTab(index)
    }


    useEffect(() => {
        if (typeof window !== undefined) {
            const pastTab = localStorage.getItem('vf-account-tab');
            if (pastTab) {
                setActiveTab(+pastTab)
            }
        }
    }, [])

    useEffect(() => {
        if (typeof window !== undefined) {
            localStorage.setItem('vf-account-tab', activeTab)
        }
    }, [activeTab])

    useEffect(() => {
        if (activeSubscription) {
            const refresh = subscriptions.find(sub => sub.id === activeSubscription.id)
            const payment = paymentMethods.find(method => method.id == refresh.default_payment_method);
            const seatDoc = seatDocs.find(doc => doc.id === refresh.docId);
            setActiveSubscription({...refresh, payment: payment.card, seats: seatDoc ? seatDoc.seats : null})
        }
    }, [subscriptions])

    const changeActiveSubscription = (id) => {
        const active = subscriptions.find(sub => sub.id === id);
        if (active) {
            let seatDoc;
            const payment = paymentMethods.find(method => method.id == active.default_payment_method);
            if (seatDocs) {
                const seatDoc = seatDocs.find(doc => doc.id === active.docId);
            }
            setActiveSubscription({...active, payment: payment.card, seats: seatDoc ? seatDoc.seats : []})
        }
    }

    return (
        <React.Fragment>
            <div className="account-tabs">
                <div className="container">
                    {accountsTabs.map(({key, title}) => {
                        return (
                          <button
                            className={`tab ${activeTab === key ? 'active' : ''}`}
                            key={key}
                            onClick={() => changeTab(key)}
                          >
                            {title}
                          </button>
                        )
                    })}

                </div>
            </div>
            <div className="account-dropdown">
                <select onChange={(e) => changeTab(+e.target.value)}>
                    {accountsTabs.map(({key, title}) => {
                        return <option value={key} key={key}>{title}</option>
                    })}

                </select>
            </div>
            <div className="account-tab-content">
                { activeSubscription === null ?
                  (
                    <div className="my-account-wrapper">
                        <div className={`tab-content ${activeTab === 0 ? 'active' : ''}`}>
                            <AccountSettings user={user} userData={userData} />
                        </div>
                        <div className={`tab-content ${activeTab === 1 ? 'active' : ''}`}>
                            <ManageSubscriptions subscriptions={subscriptions} onChildClick={changeActiveSubscription} />
                        </div>
                        <div className={`tab-content ${activeTab === 2 ? 'active' : ''}`}>
                            <ManageSeats seats={seats} seatDocs={seatDocs} />
                        </div>
                        <div className={`tab-content ${activeTab === 3 ? 'active' : ''}`}>
                            <OrderHistory userData={userData} />
                        </div>
                        <div className={`tab-content ${activeTab === 4 ? 'active' : ''}`}>
                            <Support userData={userData} form={form} />
                        </div>
                        <div className={`tab-content ${activeTab === 5 ? 'active' : ''}`}>
                            <UserAccountImage user={user} form={form} />
                        </div>
						<div className={`tab-content ${activeTab === 6 ? 'active' : ''}`}>
                            <CommunityPost />
                        </div>
                    </div>
                ) : (
                    <ManageSubscription user={user} userData={userData} paymentMethods={paymentMethods} activeSubscription={activeSubscription} />
                )}
            </div>
        </React.Fragment>
    )
}

const AccountSettings = ({ user, userData }) => {

    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
    })

    useEffect(() => {
        if (userData) {
            const { email, username, firstName, lastName, billingEmail, phone, streetAddress, city, state, customer_id } = userData
            setState({
                firstName: firstName,
                lastName: lastName,
                email: email,
                username: username,
            })
        }
      }, [userData])

    const handleChange = e => {
        const value = e.target.value
        setState({
          ...state,
          [e.target.name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const seatDocRef = await firebase.firestore().collection('Users').doc(user.uid).update({
                firstName: state.firstName ? state.firstName : '',
                lastName: state.lastName ? state.lastName : '',
                email: state.firstName ? state.email : '',
                username: state.firstName ? state.username : '',
            });
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
                <div className="col">
                    <div className="cart-form-field">
                        <label>
                            First Name *
                        </label>
                        <input
                            name="firstName"
                            type="text"
                            placeholder="First Name*"
                            autoComplete="new-password"
                            defaultValue={state.firstName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="cart-form-field">
                        <label>
                            Last Name *
                        </label>
                        <input
                            name="lastName"
                            type="text"
                            placeholder="Last Name*"
                            autoComplete="new-password"
                            defaultValue={state.lastName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="cart-form-field">
                        <label>
                            Email *
                        </label>
                        <input
                            name="email"
                            type="email"
                            placeholder="Email*"
                            defaultValue={state.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="cart-form-field">
                        <label>
                            Display Name *
                        </label>
                        <input
                            name="username"
                            type="text"
                            placeholder="Display Name*"
                            autoComplete="new-password"
                            defaultValue={state.username}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row button-row">
                <div className="col">
                    <button type="submit">
                        Save
                    </button>
                </div>
            </div>
        </form>
    )
}

const ManageSubscriptions = ({ subscriptions, onChildClick }) => {

    const handleClick = (id) => {
        onChildClick(id)
    }
    return (
        <div className="manage-subscriptions">
            {subscriptions && subscriptions.length > 0 ?
                <React.Fragment>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Order #</th>
                                <th>Status</th>
                                <th>Commitment</th>
                                <th># of Seats</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {subscriptions ? subscriptions.map(subscription => {
                                if (subscription) {
                                    return (
                                        <tr key={subscription.id}>
                                            <td>
                                                {new Date(subscription.start_date * 1000).toLocaleDateString('en-US')}
                                            </td>
                                            <td>
                                                {subscription.id}
                                            </td>
                                            <td>
                                                <span className="cap">{subscription.status}</span>
                                            </td>
                                            <td>
                                                <span className="cap">{subscription.plan.interval}</span>
                                            </td>
                                            <td>
                                                {subscription.quantity}
                                            </td>
                                            <td>
                                                <button className="text-button" onClick={() => handleClick(subscription.id)}>Edit Subscription</button>
                                            </td>
                                        </tr>
                                    )
                                }
                            }) : ''}
                        </tbody>
                    </table>

                    <div className="mobile-list">
                        {subscriptions ? subscriptions.map(subscription => {
                            if (subscription) {
                                return (
                                    <div className="item" key={subscription.id}>
                                        <div className="item-row">
                                            <span className="label">Date</span>
                                            <span className="value">{new Date(subscription.start_date * 1000).toLocaleDateString('en-US')}</span>
                                        </div>
                                        <div className="item-row">
                                            <span className="label">Order #</span>
                                            <span className="value no-cap">{subscription.id}</span>
                                        </div>
                                        <div className="item-row">
                                            <span className="label">Status</span>
                                            <span className="value">{subscription.status}</span>
                                        </div>
                                        <div className="item-row">
                                            <span className="label">Commitment</span>
                                            <span className="value">{subscription.plan.interval}</span>
                                        </div>
                                        <div className="item-row">
                                            <span className="label"># of Seats</span>
                                            <span className="value">{subscription.quantity}</span>
                                        </div>
                                        <div className="button-wrapper">
                                            <button className="text-button" onClick={() => handleClick(subscription.id)}>Edit Subscription</button>
                                        </div>
                                    </div>
                                )
                            }
                        }) : ''}

                    </div>
                </React.Fragment>
            : (
                <p>
                    You do not currently have any subscriptions. <Link to="/get-started">Click here</Link> to get started.
                </p>
            )}
        </div>
    )
}

const ManageSubscription = ({ user, userData, paymentMethods, activeSubscription }) => {

    const stripe = useStripe();
    const elements = useElements();

    const [selectedSeats, setSelectedSeats] = useState([])
    const [seats, setSeats] = useState([])
    const [editPayment, setEditPayment] = useState(false);
    const [addPayment, setAddPayment] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);

    const handleSelect = (ev) => {
        const status = ev.target.checked;
        const id = ev.target.name;
        if (status) {
            setSelectedSeats([...selectedSeats, id])
        } else {
            setSelectedSeats(selectedSeats.filter(seat => seat !== id))
        }
    }

    useEffect(() => {
        const unsubscribe = firebase.firestore()
        .collection('seats')
        .doc(activeSubscription.docId)
        .onSnapshot(snapshot => {
            if (snapshot.exists) {
                const data = snapshot.data();
                setSeats(data.seats);
            }
        }, err => {
            console.error(err)
        })
        return () => unsubscribe()
    }, [activeSubscription])


    const handleRemove = async () => {
        try {
            const price = activeSubscription.plan.id;
            const itemId = activeSubscription.items.data[0].id;
            const currentQuantity = seats.length;
            const newQuantity = currentQuantity - selectedSeats.length;
            const body = {
                quantity: newQuantity
            }
            const response = await fetch(`https://us-central1-vf-stand-designer.cloudfunctions.net/app/api/vsd/subscription/${activeSubscription.id}/update`, {
                method: 'POST',
                body: JSON.stringify(body)
            })

            const data = await response.json()
            if (data.id) {
                const seatDocRef = await firebase.firestore().collection('seats').doc(activeSubscription.docId);
                const seatData = await seatDocRef.get();
                const { seats, seatRef } = seatData.data();
                seatDocRef.update({
                    seats: seats.map(seat => {
                        if (selectedSeats.includes(seat.seatId)) {
                            seat.expires = activeSubscription.current_period_end
                        }
                        return seat;
                    }),
                    // seatRef: seatRef.filter(item => !selectedSeats.includes(item))
                })
                toast('Seat has been removed.', 4000)
                setSelectedSeats([])
            }

        } catch (e) {
            console.error(e)
        }
    }

    const cancelSubscription = async (event) => {
        event.preventDefault()
        const cancel = await fetch(`https://us-central1-vf-stand-designer.cloudfunctions.net/app/api/vsd/subscription/${activeSubscription.id}/cancel`, {
            method: 'POST'
        })
        toast('Subscription has been canceled.', 4000)
    }

    const resumeSubscription = async (event) => {
        event.preventDefault()
        const resume = await fetch(`https://us-central1-vf-stand-designer.cloudfunctions.net/app/api/vsd/subscription/${activeSubscription.id}/resume`, {
            method: 'POST'
        })
        toast('Subscription has been resumed.', 4000)
    }

    const handleEditPayment = (e) => {
        e.preventDefault();
        setEditPayment(!editPayment)
    }

    const selectPayment = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        if (checked) {
          setSelectedPayment(value)
        } else {
          setSelectedPayment(null)
        }
    }

    // const selectPayment = (id) => {
    //     setSelectedPayment(id)
    // }

    const savePaymentMethod = async () => {
        try {
            const body = {
                default_payment_method: selectedPayment
            }
            const response = await fetch(`https://us-central1-vf-stand-designer.cloudfunctions.net/app/api/vsd/subscription/${activeSubscription.id}/update`, {
                method: 'POST',
                body: JSON.stringify(body)
            })
            const data = await response.json();
            toast('Payment method has been changed', 4000)
            setEditPayment(false)
        } catch (e) {

        }
    }

    const toggleAddPayment = (e) => {
        e.preventDefault();
        setAddPayment(!addPayment)
    }

    const addPaymentMethod = async (e) => {
        try {
            e.preventDefault();
            if (!stripe || !elements) {
                return;
            }
            const cardElement = elements.getElement(CardElement)
            const {stripeError, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            })
            if (stripeError) {
                // console.log('[error]', error);
                setState({ ...state, error: error.message })
                return
            }
            const body = {
                userId: user.uid,
                paymentId: paymentMethod.id,
                customerId: userData.customer_id
            }
            const response = await fetch(`https://us-central1-vf-stand-designer.cloudfunctions.net/app/api/vsd/subscription/${activeSubscription.id}/add-payment`, {
                method: 'POST',
                body: JSON.stringify(body)
            })
            const data = await response.json();
            toast('Payment method has been changed', 4000)
            setAddPayment(false)
        } catch (err) {
            console.error(err)
        }
    }


    return (
        <div className="my-account-wrapper subscription-details">
            <div className="subscription-details-header">
                <p>
                    <span className="label">Subscription</span> <strong>{userData.email}</strong> on Visual Forester &bull; <strong>{activeSubscription.id}</strong>
                </p>
            </div>
            <div className="payment">
                <h2>Payment</h2>
                <p>
                    <span className="label">Started</span> {new Date(activeSubscription.start_date * 1000).toLocaleDateString('en-US')}
                    <span className="label">Last Invoice</span> {new Date(activeSubscription.current_period_start * 1000).toLocaleDateString('en-US')}
                    <span className="label">Next Invoice</span> {new Date(activeSubscription.current_period_end * 1000).toLocaleDateString('en-US')}
                    {activeSubscription.cancel_at ? (
                        <React.Fragment>
                            <span className="label">End Date</span>  {new Date(activeSubscription.cancel_at * 1000).toLocaleDateString('en-US')}
                        </React.Fragment>
                    ) : ''}

                    {activeSubscription.cancel_at ? (
                        <a onClick={(e) => resumeSubscription(e)} href="#">Resume</a>
                    ) : (
                        <a onClick={(e) => cancelSubscription(e)} href="#">Cancel</a>
                    )}

                </p>
            </div>
            <div className="payment-details">
                <h2>Payment Details</h2>
                <p>
                    <span className="label">Billing Method: </span> <img src={`/${activeSubscription.payment.brand}.svg`} className="brand" /> &bull; &bull; &bull; &bull; &nbsp; &bull; &bull; &bull; &bull; &nbsp; &bull; &bull; &bull; &bull; &nbsp; {activeSubscription.payment.last4}
                    {(paymentMethods.length > 1) && !addPayment ? (
                        <a href="#" onClick={(e) => handleEditPayment(e)}>
                            {editPayment ? ('Cancel') : ('Change')}
                        </a>
                    ) : ''}
                    {!editPayment ? (
                        <a href="#" onClick={(e) => toggleAddPayment(e)}>
                            {addPayment ? ('Cancel') : ('Add')}
                        </a>
                    ): ''}
                </p>
                {editPayment ? (
                    <div className="edit-payment">
                        <span className="label">Select Payment Method</span>
                        {paymentMethods && paymentMethods.map(method => {
                            if (method.id === activeSubscription.default_payment_method) {
                                return;
                            }
                            return (
                                <div key={method.id} className={selectedPayment=== method.id ? 'payment-method active' : 'payment-method'}>
                                    <label>
                                    <input type="checkbox" key={method.id} value={method.id} checked={selectedPayment === method.id} onChange={(e) => selectPayment(e)} />
                                    <img src={`/${method.card.brand}.svg`} className="brand" />
                                    <span className="numbers">&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {method.card.last4}</span>  <span className="exp">Exp {method.card.exp_month}/{method.card.exp_year}</span>
                                    </label>
                                </div>
                            )
                        })}
                        <button onClick={savePaymentMethod}>
                            Update payment method
                        </button>
                    </div>
                ) : null}

                {addPayment ? (
                    <form style={{marginTop: '2rem'}} onSubmit={(e) => addPaymentMethod(e)}>
                        <CardElement
                            options={{
                                style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                    color: '#aab7c4',
                                    }
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                                },
                            }}

                        />
                        <button type="submit">
                            Add payment method
                        </button>
                    </form>
                ) : ''}

            </div>
            <div className="manage-seats">
                <h2>Manage Seats</h2>
                {seats && seats.length ? (
                    <React.Fragment>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Seat ID</th>
                                    <th>Device Name</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {seats && seats.map(seat => {
                                    return (
                                        <tr key={seat.seatId}>
                                            <td style={{width: 'calc(35px + 1em)', paddingRight: '2rem'}}>
                                                <input type="checkbox" name={seat.seatId} onChange={(e) => handleSelect(e)}></input>
                                            </td>
                                            <td>
                                                {seat.seatId}
                                            </td>
                                            <td>
                                                {seat.computerName ? seat.computerName : ''}
                                            </td>
                                            <td>
                                                {seat.expires ? 'Cancelled ' + new Date(seat.expires * 1000).toLocaleDateString('en-US') : 'Active'}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="button-container">
                            <button onClick={handleRemove} disabled={!selectedSeats.length}>
                                Remove
                            </button>
                        </div>
                    </React.Fragment>

                ) : 'There are no seats attached to this subscription'}

            </div>
        </div>
    )
}

const ManageSeats = ({ seats, seatDocs }) => {

    const [selectedSeats, setSelectedSeats] = useState([])
    const [totalSeats, setTotalSeats] = useState([])

    useEffect(() => {
        if (!seatDocs || seatDocs.length < 1) return
        const seats = seatDocs.reduce((a, b) => {
            return a.concat(b.seats)
        }, [])
        setTotalSeats(seats)
    }, [seatDocs])

    const handleChange = (ev) => {
        const status = ev.target.checked;
        const id = ev.target.name;
        if (status) {
            setSelectedSeats([...selectedSeats, id])
        } else {
            setSelectedSeats(selectedSeats.filter(seat => seat !== id))
        }
    }

    const removeDevices = async () => {
        try {
            seatDocs.forEach(async doc => {
                const id = doc.id
                const seatDocRef = await firebase.firestore().collection('seats').doc(id);
                const seatData = await seatDocRef.get();
                const { seats } = seatData.data();
                seatDocRef.update({
                    seats: seats.map(seat => {
                        if (selectedSeats.includes(seat.seatId)) {
                            seat.computer = null;
                            seat.computerName = null;
                        }
                        return seat;
                    })
                })
            })
            toast('Devices have been removed', 4000)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="manage-keys">
            {seats && seats.length > 0 ? (
                <React.Fragment>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Seat ID</th>
                                <th>Device Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {totalSeats && totalSeats.map(seat => {
                                return (
                                    <tr key={seat.seatId}>
                                        <td style={{width: 'calc(35px + 1em)', paddingRight: '2rem'}}>
                                            <input type="checkbox" name={seat.seatId} onChange={(e) => handleChange(e)}></input>
                                        </td>
                                        <td>
                                            {seat.seatId}
                                        </td>
                                        <td>
                                            {seat.computerName ? seat.computerName : ''}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="mobile-list">
                        {totalSeats && totalSeats.map(seat => {
                            return (
                                <div className="item" key={seat.seatId}>
                                    <div className="check">
                                        <input type="checkbox" name={seat.seatId} onChange={(e) => handleChange(e)}></input>
                                    </div>
                                    <div className="item-row">
                                        <span className="label">License Key</span>
                                        <span className="value no-cap">{seat.seatId}</span>
                                    </div>
                                    <div className="item-row">
                                        <span className="label">Device Name</span>
                                        <span className="value">{seat.computerName ? seat.computerName : 'n/a'}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="button-group">
                        <button className="button light" disabled={selectedSeats.length < 1} onClick={() => removeDevices()}>
                            Remove Device{selectedSeats.length > 1 ? 's' : ''}
                        </button>
                        <Link className="button light" to="/get-started">
                            Buy More Seats
                        </Link>
                        <a className="button light" href="https://firebasestorage.googleapis.com/v0/b/vf-stand-designer.appspot.com/o/Setup%2FStand%20Designer%20Setup.exe?alt=media&token=669afe55-2b03-46d9-ab4e-19858a65b8a0">
                            Download
                        </a>
                    </div>
                </React.Fragment>
            ) : (
                <p>
                    You do not currently have any subscriptions. <Link to="/get-started">Click here</Link> to get started.
                </p>
            )}
        </div>
    )
}

const OrderHistory = ({ userData }) => {

    const [invoices, setInvoices] = useState(null)

    useEffect(() => {
        (async () => {
            if (userData) {
                if (userData.customer_id) {
                    const response = await fetch(`https://us-central1-vf-stand-designer.cloudfunctions.net/app/api/vsd/customer/${userData.customer_id}/invoices`, {
                        method: 'GET'
                    })
                    const data = await response.json();
                    setInvoices(data.data)
                }
            }
        })()
    }, [userData])

    const formatAmountForStripe = (amount, currency) => {
        return zeroDecimalCurrency(amount, currency)
          ? amount
          : Math.round(amount / 100);
    }

    const zeroDecimalCurrency = (amount, currency) => {
        let numberFormat = new Intl.NumberFormat(['en-US'], {
          style: 'currency',
          currency: currency,
          currencyDisplay: 'symbol',
        });
        const parts = numberFormat.formatToParts(amount);
        let zeroDecimalCurrency = true;
        for (let part of parts) {
          if (part.type === 'decimal') {
            zeroDecimalCurrency = false;
          }
        }
        return zeroDecimalCurrency;
    }

    return (
        <React.Fragment>
            {invoices && invoices.length > 0 ? (
                <div className="order-history">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Subscription</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices && invoices.map(invoice => {
                                return (
                                    <tr key={invoice.id}>
                                        <td>
                                            {invoice.id}
                                        </td>
                                        <td>
                                            <span className="cap">{invoice.status}</span>
                                        </td>
                                        <td>
                                            {new Date(invoice.created * 1000).toLocaleDateString('en-US')}
                                        </td>
                                        <td>
                                            ${formatAmountForStripe(invoice.amount_paid, 'usd')}
                                        </td>
                                        <td>
                                            {invoice.subscription}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

            ) : (
                <p>
                    You do not currently have any subscriptions. <Link to="/get-started">Click here</Link> to get started.
                </p>
            )}
        </React.Fragment>
    )
}

const Support = ({ userData, form }) => {

    const [state, setState] = useState({
        formFields: form.formFields,
        isSubmitting: false,
        message: '',
        successMessage: 'Thanks for contacting us! We will get in touch with you shortly.',
        status: null
    })

    const [fields, setFields] = useState({
        1: '',
        2: '',
        3: '',
        4: '',
        form_id: form.formId
    })

    const handleChange = (e) => {
        const value = e.target.value

        setFields({
          ...fields,
          [e.target.name]: value
        })
    }

    const handleSubmit = (ev) => {
        setState({ ...state, isSubmitting: true});
        ev.preventDefault();
        const form = ev.target
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, form.action)
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa(`${process.env.GRAVITYFORM_KEY}:${process.env.GRAVITYFORM_SECRET}`))
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 201) {
                setState({ ...state, message: 'Thanks for contacting us! We will get in touch with you shortly.' });
            } else {
                setState({ ...state, message: 'There was an error' });
            }
            setState({ ...state, isSubmitting: false });
        }
        xhr.send(JSON.stringify(fields))
    }

    return (
        <div className="support">
            <div className="content-box">
                <h1>Contact</h1>
                <h2>Our Support Team</h2>
                <p>
                    For tips or troubleshooting, feel free to contact the Visual Forester Support team at any time.
                </p>
            </div>
            <div className="form">
                <form method="post" action={form.apiURL + '/entries'} onSubmit={(e) => handleSubmit(e)}>
                    <div className="row">
                        <div className="col">
                            <input type="text" name="1" placeholder="Name*" required="required" defaultValue={state.name} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <input type="text" name="2" placeholder="Email*" required="required" defaultValue={state.email} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <select name="4" onChange={(e) => handleChange(e)}>
                                <option>I'm seeking support for</option>
                                <option value="Visual Forester">Visual Forester</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <textarea name="3" placeholder="Describe your issue" onChange={(e) => handleChange(e)}></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col end">
                            <button type="submit" disabled={state.isSubmitting}>
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>
                                {state.message}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const MyAccount = ({ forms }) => {

    const [user, loading, error, userData, paymentMethods, subscriptions, seats, seatDocs] = useAuthState(firebase)

    const form = forms.nodes.find(node => node.slug === 'support');

    useEffect(() => {
        if (!user && !loading) {
            navigate('/login')
        }
    })

    const logout = async e => {
        e.preventDefault()
        try {
            await firebase.auth().signOut()
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <section className="account-header">

            <div className="account-title-bar">
                <div className="container">
                    <div className="account-title">
                        {userData ? userData.firstName + '\'s' : ''} Account
                    </div>
                    <div className="accout-log-out">
                        <a href="#" onClick={logout}>Log Out</a>
                    </div>
                </div>
            </div>

            <MyAccountTabs user={user} userData={userData} paymentMethods={paymentMethods} subscriptions={subscriptions} seats={seats} seatDocs={seatDocs} form={form} />

        </section>
    )
}

const CommunityPost = ({ userData, form }) => {


    return (
        <div className="support">
          <div className="content-box">
		  <h1>
		  Have a forest you are really proud of? Tell us all about it
		  	  <br></br>
			  <br></br>
		  </h1>
          <p>
		  Visual Forester is proud to be an integral part of the forestry community. You can post your own Visual Forester forests using the link below. These posts can include text, 1 main banner photo, 2 additional photos, and even the .vl file!
		  <br></br>
		  <br></br>
		  Your email is also collected for internal purposes and will not be posted.
		  It can take up to 5 minutes before your post will go live on the community page. If you are having trouble posting, want to post more pictures or a video, or need to make edits please contact nbruno@alpine-lis.com

		      </p>
		  <br></br>
		  <br></br>
		  <h2>
		  <strong><a href=" https://api.visualforester.com/n7brttvvfkcj29qi04x5oaseqnkjywoncnd40j5ua6n9astsjnjyr4cmk4at/">Click Here </a></strong> to post your own Visual Forester forest
		  </h2>	
			</div>
		</div>
    )
}



export default MyAccount
