import React from "react"
import PropTypes from "prop-types"
const ButtonBase = ({ children, handle, styleClass }) => {
  return (
    <button onClick={handle} className={styleClass}>
      {children}
    </button>
  )
}

ButtonBase.propTypes = {
  handle: PropTypes.func,
  styleClass: PropTypes.string,
}

ButtonBase.defaultProps = {
  handle: () => {},
  styleClass: "",
}

export default ButtonBase
