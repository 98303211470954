import React from 'react'

const Faq = ({ fields }) => {
  return (
    <React.Fragment>
      {fields.faqs ? (
        <section className="faqs">
          <div className="container">
            <div className="row">
              <div className="col">
                {fields.faqs.length > 0 && fields.faqs.map((faq, index) => {
                  return (
                    <div key={`faq-${index}`} className={`faq faq-${index}`}>
                      <h2 dangerouslySetInnerHTML={{ __html: faq.question }}></h2>
                      <div className="content" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      ) : ''}
    </React.Fragment>
  );
}

export default Faq
