import React, { useState, useEffect } from "react"
import NewsletterSignUp from "./NewsletterSignUp"
import SocialLinks from "./SocialLinks"
import ReactPlayer from "react-player/lazy"
import { getCookie, setCookie } from "react-use-cookie"

const Popup = ({ data }) => {
  const [popup, setPopup] = useState(true)

  useEffect(() => {
    const cookie = getCookie("hidepopup")
    setPopup(cookie)
  }, [])

  const handlePopup = () => {
    setCookie("hidepopup", true)
    setPopup(true)
  }

  return (
    <>
      {data.show && !popup ? (
        <div className="modal modal-popup">
          <div className="modal-outside" onClick={handlePopup}></div>
          <div className="modal-wrap">
            <button className="close" onClick={handlePopup}>
              x
            </button>

            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={data.video}
                controls
                width="100%"
                height="100%"
              />
            </div>

            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></div>

            <NewsletterSignUp />

            <div className="social">
              {data.title ? (
                <div className="social-title">{data.title}</div>
              ) : null}

              <SocialLinks data={data.social} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Popup
