import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import SvgBasket from "../../../assets/images/basket.svg"
import SvgReplace from "../../../assets/images/replace.svg"
import SvgHandle from "../../../assets/images/handle.svg"
import { ReactSortable } from "react-sortablejs"

const AllImageFile = ({ children, filesName, handleRemove, handleReplace, arrayFiles, setArrayFiles }) => {

  const inputFile = useRef(null)
  let currentId = useRef(null)

  const handleLabel = (index, e) => {
    let files = [...arrayFiles]
    files[index]['label'] = e.target.value

    setArrayFiles(files)
  }

  const setOrder = (files) => {
    files.map((file, index) => {
      file.order = index
    })

    setArrayFiles(files)
  }

  return (
    <div className="all-image-file">
      <input id="replace-file-input" type="file" ref={inputFile} onChange={() => {handleReplace(currentId, inputFile)}} accept=".jpg,.jpeg,.png" />

      <ReactSortable handle={'.handle'} list={arrayFiles} setList={setOrder}>
        {arrayFiles.map(({ name, label, order, lastModified } , index) => {
          return (
            <div className={"wrapper-remove-btn order-" + order} key={index}>
              <span className={"handle"}>
                <img src={SvgHandle} alt="handle" width="20" height="20" />
              </span>
              <div className="label">
                  <input type="text" name="file-labels[]" value={label} onChange={(e) => handleLabel(index, e)} />
              </div>
              <div className="file-name">
                  {name}
              </div>
              <span
                  className={"replace-btn"}
                  onClick={() =>{
                    currentId = lastModified
                  }}>
                <label htmlFor="replace-file-input">
                  <img src={SvgReplace} alt="replace" />
                </label>
              </span>
              <span
                className={"remove-btn"}
                onClick={() => {
                  handleRemove(lastModified, name)
                }}
              >
                <img src={SvgBasket} alt="basket" />
              </span>
            </div>
          )
        })}
      </ReactSortable>
    </div>
  )
}

AllImageFile.propTypes = {
  filesName: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.any,
    })
  ),
}

AllImageFile.defaultProps = {
  handeButton: () => {},
  filesName: [],
}
export default AllImageFile
