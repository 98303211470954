import React, { useRef, useMemo, useState, useEffect } from "react"
import PropTypes, {array} from "prop-types"
import InputLabel from "../common/InputLabel"
import BaseTextarea from "../common/BaseTextarea"
import AllImageFile from "./AllImageFiles"
import BaseButton from "../common/BaseButton"

const inputFilesToArray = (files) => {
  return Object.keys(files).map((key) => files[key])

}
const getImageNameId = (item) => ({
  name: item.name,
  id: item.lastModified,
})

function renameFile(originalFile, newName, rand, index) {
  const file = new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified+rand,
  })

  Object.defineProperty(file, 'label', {
    value: newName.replace(/\.[^/.]+$/, ""),
    writable: true
  })

  Object.defineProperty(file, 'order', {
    value: index,
    writable: true
  })

  return file
}

const UploadFiles = ({ children, publishFiles, initState = null, removeImageFireBase}) => {
  const [metaData, setMetaData] = useState({ label: "", description: "" })
  const [arrayFiles, setArrayFiles] = useState([])
  const filesName = useMemo(() => arrayFiles.map(getImageNameId), [arrayFiles])
  const inputFile = useRef(null)
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (typeof initState !== "object" || !initState) return
    setMetaData({ ...initState?.metaData })
    setArrayFiles(initState?.files || [])
  }, [])

  const isWrongExtension = (files) => {
    let error

    Array.from(files).map((file) => {
      const extension = file.name.split('.').pop().toLowerCase()

      if (extension !== 'jpg' &&
          extension !== 'png' &&
          extension !== 'jpeg') {
        error = 'Accepted extensions are .jpg, .png, .jpeg'
      }
    })

    if (error) {
      setErrorMessage('Accepted extensions are .jpg, .png, .jpeg')
      return true
    }
  }

  const replaceFile = (id, inputFile) => {
    let fileIndex = 0
    const find = arrayFiles.find(file => file.lastModified === id)

    arrayFiles.find((file, index) => {
      if (file.lastModified === id) {
        fileIndex = index
      }
    })

    if (isWrongExtension(inputFile.current.files)) return

    const files = Array.from(inputFile.current.files).map((item, index) => {
      return renameFile(item, item.name, Math.ceil(Math.random() * 10000), index)
    })

    if (!files.length) return

    const replacedFiles = [...arrayFiles]
    replacedFiles[fileIndex] = files[0]

    setArrayFiles(replacedFiles)

    if (!find.url) return

    if (typeof removeImageFireBase === 'function') {
      removeImageFireBase(find.url)
    }
  }

  const removeFiles = (id) => {
    const find = arrayFiles.find(file => file.lastModified === id)
    console.log(find)
    const filterFiles = arrayFiles.filter((file) => file.lastModified !== id)
    setArrayFiles([...filterFiles]);

    if (!find.url) return

    if (typeof removeImageFireBase === 'function') {
      removeImageFireBase(find.url)
    }
  }

  const uploadFile = (e) => {
    if (isWrongExtension(inputFile.current.files)) return

    if ( Array.from(inputFile.current.files).length > 20 ) {
      e.preventDefault();
      setErrorMessage('Please upload less than 20 photos');
      return;
    }

    const files = Array.from(inputFile.current.files).map((item, index) => {
      return renameFile(item , item.name , Math.ceil(Math.random() * 10000), index);
    })

    if (!files.length) return

    setArrayFiles([...arrayFiles, ...inputFilesToArray(files)]);

    setErrorMessage('');
  }

  const sendForm = (e) => {
    publishFiles({
      files: arrayFiles,
      metaData
    })
  }

  const changeDescription = (val, key) => {
    setMetaData({ ...metaData, [key]: val })
  }

  return (
    <React.Fragment>
      <div className="image-upload">
        <h2>Build Image Comparison</h2>
        <div className="help-paragraph">
          To share your images using this tool:
          <ul>
            <li>Upload images from your computer. You can select two to twenty images to compare.</li>
            <li>(Optional) Add a title and description for your comparison and edit the labels on your images.</li>
            <li>Click Publish. Visual Forester will instantly create a viewer where you can compare your images with an interactive slider.
              You will get a unique link to this viewer which you can add to your website or share via email.</li>
          </ul>
        </div>
        <div className="button-section upload-file-buttons">
          <div className="upload-image-block">
            <div className="upload-image-input">
              <input type="file" ref={inputFile} multiple onChange={uploadFile} accept=".jpg,.jpeg,.png" />
              <span>Upload Images</span>
            </div>
            { errorMessage &&
            <div className="error"> { errorMessage } </div> }
          </div>
          <a href="https://vimeo.com/680649926/6a7dac0781" className="button" target="_blank">Watch How-To Video</a>
        </div>
        <div className="grid-box">
          <InputLabel
            handle={changeDescription}
            property={"label"}
            value={metaData.label}
          />
          <BaseTextarea
            handle={changeDescription}
            property={"description"}
            value={metaData.description}
          />
        </div>
        <AllImageFile filesName={filesName} handleRemove={removeFiles} handleReplace={replaceFile} arrayFiles={arrayFiles} setArrayFiles={setArrayFiles}>
          Remove
        </AllImageFile>
      </div>
      <BaseButton
        handle={sendForm}
        styleClass={"not-button-round button-primary"}
      >
        {children}
      </BaseButton>
    </React.Fragment>
  )
}

UploadFiles.propsType = {
  publishFiles: PropTypes.func,
}

export default UploadFiles
