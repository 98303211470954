import React from "react";
export default [
  {
    title: 'Account Settings',
    key: 0,
  },
  {
    title: 'Manage Subscriptions',
    key: 1,
  },
  {
    title: 'Manage Seats',
    key: 2,
  },
  {
    title: 'Image Comparison',
    key: 5,
  },
  {
    title: 'Order History',
    key: 3,
  },
  {
    title: 'Support',
    key: 4,
  },
    {
    title: 'Community Post',
    key: 6,
  },
]
