import React, { useState, useContext, useEffect, useLayoutEffect } from 'react'
import EyeIcon from '../assets/svg/eye_icon.svg'
import firebase from 'gatsby-plugin-firebase'
import useAuthState from '../functions/useAuthState'
// import { AuthContext } from '../context/auth'
import { navigate, Link } from 'gatsby'
import toast from 'react-simple-toasts'

const ResetPassword = () => {
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
    error: null
  })

  const [user, loading, error] = useAuthState(firebase)

  const [resetCode, setResetCode] = useState(null)

  const togglePassword = e => {
    setState({...state, showPassword: !state.showPassword})
  }

  const handleChange = e => {
    const value = e.target.value
    setState({ 
      ...state,
      [e.target.name]: value
    })
  }

  useLayoutEffect(() => {
    if (typeof window !== undefined) {
      const query = new URLSearchParams(window.location.search)
      const code = query.get('oobCode');
      setResetCode(code)
    }
  }, [])
  
  const handleSubmit = async (e) => {
      e.preventDefault()
    try {
      // console.log(resetCode)
      const response = await firebase.auth().confirmPasswordReset(resetCode, state.password);
      // console.log(response)
      toast('Password has been reset', 4000)
      navigate('/login')
    } catch (err) {
        console.error(err)
    }
  }


  return (
    <React.Fragment>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="reset-password-section">
              <div className="login-heading">
                <h4>Reset password</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="cart-form-field">
                  <div className="row">
                    <div className="col">
                      <div className="cart-form-field">
                        <input
                          name="password" 
                          type={state.showPassword ? 'text' : 'password'} 
                          placeholder="Password*"
                          autoComplete="new-password"
                          required="required"
                          onChange={handleChange}
                        />
                        <div className="see-pwd">
                          <button type="button" onClick={togglePassword} tabIndex="-1">
                            <img src={EyeIcon}/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="cart-form-field">
                        <input
                          name="confirmPassword" 
                          type={state.showPassword ? 'text' : 'password'} 
                          placeholder="Confirm Password*"
                          autoComplete="new-password"
                          required="required"
                          onChange={handleChange}
                        />
                        <div className="see-pwd">
                          <button type="button" onClick={togglePassword} tabIndex="-1">
                            <img src={EyeIcon}/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="signin-container">
                  <button className="button button-dark" disabled={(state.password === '') || (state.password !== state.confirmPassword)}>Change Password</button>
                </div>
                {state.error ? <p>{state.error}</p> :  null}
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ResetPassword