import React from "react"
import PropTypes from "prop-types"

const BaseTextarea = ({ handle, property, value = '' }) => {
  const changeValue = (val) => handle(val, property)
  return (
    <div className="description">
      <textarea
        defaultValue={value}
        onInput={({ target }) => {
          changeValue(target.value)
        }}
        placeholder="Description"
        name=""
        id=""
        cols="30"
        rows="10"
      />
    </div>
  )
}

BaseTextarea.propTypes = {
  handle: PropTypes.func.isRequired,
  property: PropTypes.any,
}

export default BaseTextarea
