import firebase from "gatsby-plugin-firebase"

const downloadAllImageUser = (path) => {
  const storageRef = firebase.storage().ref()
  const listRef = storageRef.child(`images/${path}`)
  const items = []

  return listRef
    .listAll()
    .then((res) => {
      res.items.forEach((itemRef) => {
        if (itemRef.name === "metadata") return
        items.push({ fullPath: itemRef.fullPath, name: itemRef.name })
      })
      if (!items.length) return Promise.resolve()
      return downloadFullExample(items)
    })
    .catch((error) => {
      console.log(error)
    })
}

const downloadFullExample = (itemsImg) => {
  const list = itemsImg.map(({ fullPath, name }) => {
    const storageRef = firebase.storage().ref()
    const starsRef = storageRef.child(fullPath)
    const meta = starsRef
      .getMetadata()
      .then((data) => {
        return data?.customMetadata
      })
      .catch((error) => {
        console.log(error)
        return null
      })

    const files = starsRef
      .getDownloadURL()
      .then((url) => {
        return {
          url,
          name,
          lastModified: fullPath
        }
      })
      .catch((error) => {
        console.log(error)
        return null
      })

    return Promise.all([meta, files])
  })

  const files = Promise.all(list).then(result => {
    if (!result.length) return

    result = result.map(file => {
      return { ...file[0], ...file[1] }
    })

    return result
  })

  return files
}

const setMetaData = (path, metaData) => {
  const storageRef = firebase.storage().ref(`images/${path}/metadata`)
  const uint8 =
    "data:text/plain;base64,5b6p5Y+344GX44G+44GX44Gf77yB44GK44KB44Gn44Go44GG77yB"
  return storageRef.put(uint8, { customMetadata: metaData })
}

const uploadToStorage = async (path, file) => {
  const storageRef = firebase.storage().ref(`images/${path}/${file.name}`)

  return await storageRef.put(file).then((e) => {
    updateMeta(path, file)
  })
}

const updateMeta = async (path, file) => {
  const storageRef = firebase.storage().ref(`images/${path}/${file.name}`)

  return await storageRef.updateMetadata({customMetadata: {'label': file.label, 'order': file.order}}).then((e) => {
    return e
  })
}

const uploadImage = (inputsFiles, path, metaData) => {
  if (!Array.isArray(inputsFiles)) {
    if (inputsFiles instanceof NodeList) {
      inputsFiles = Array.from(inputsFiles)
    } else
      throw error(
        'the function "uploadImage" is waiting for an array type argument'
      )
  }

  return setMetaData(path, metaData).then(() => {
    inputsFiles = inputsFiles.map(async (file, index) => {
      if (file.url) {
        await updateMeta(path, file, index)
      } else {
        await uploadToStorage(path, file, index)
      }
    })

    return Promise.all(inputsFiles)
  })
}

const removeFolder = (path) => {
  const storageRef = firebase.storage().ref(`images/${path}`)
  return storageRef.listAll().then((listResults) => {
    const promises = listResults.items.map((item) => {
      return item.delete()
    })
    return Promise.all(promises)
  })
}

const removeImage = async (url) => {
  const desertRef = firebase.storage().refFromURL(url)
  const result = await desertRef.delete()
  return result
}

const getMetaData = async (path) => {
  const storageRef = await firebase.storage().ref()
  const listRef = await storageRef.child(`images/${path}`)
  const result = await listRef.getMetadata()
  return result
}

export const getFolder = async (path) => {
  const storageRef = await firebase.storage().ref(`images/${path}`)
  const folders = await storageRef.listAll()
  let prefixes = folders?.prefixes || []
  prefixes = prefixes.map((item) => {
    return item.fullPath.split("/").pop()
  })
  const promises = prefixes.map((folder) => {
    return getMetaData(`${path}/${folder}/metadata`).then((metaData) => {
      return {
        folder,
        metaData: metaData?.customMetadata,
      }
    })
  })

  return Promise.all([...promises])
}

export {
  downloadAllImageUser,
  uploadImage,
  removeFolder,
  getMetaData,
  removeImage,
}
