import React, { useEffect, useState } from "react"
import CreateImage from "./image-upload/CreateImage"
import EditImage from "./image-upload/EditImage"
import ListCompare from "./ListCompare"
import { getFolder, removeFolder } from "../../service/fire-base-image"
import Spinner from "../Spinner"
import BaseButton from "./common/BaseButton"

const UserAccountImage = ({ user }) => {
  const [loading, setLoading] = useState(true)
  const [folders, setFolders] = useState([])
  const [edit, setEdit] = useState(null)

  const getAsyncFolder = () => {
    return getFolder(user.uid).then((result) => {
      setFolders(result)
    })
  }

  useEffect(() => {
    if (!user?.uid) return
    setLoading(true)
    getAsyncFolder().then(() => {
      setLoading(false)
    })
  }, [user])

  const removeCompare = async (id) => {
    setLoading(true)
    const newFolders = folders.filter((item) => item.folder !== id)
    setFolders(newFolders)
    await removeFolder(`${user?.uid}/${id}`)
    setLoading(false)
  }

  const editFolder = (id) => {
    setEdit(id)
    if (id === null) {
      getAsyncFolder().then(() => {
        setLoading(false)
      })
    }
  }

  const tabCreateImage = () => {
    setEdit("create-image")
  }

  if (folders.length && edit === null)
    return (
      <React.Fragment>
        <div className="image-comparison">
          <div className="help-paragraph">
            Share your images and allow others to compare and contrast different conditions using an interactive slider. Each time you set up a new comparison, a sharable link will be added to the list below. Anyone with this link will be able to view and compare your images. Your links/images will not be publicly listed or distributed by Visual Forester. Click “Build Image Comparison” or “Watch How-To Video” to get started.
          </div>
          <div className={"mb-30 button-section"}>
            <BaseButton handle={tabCreateImage}>Build Image Comparison</BaseButton>
            <a href="https://vimeo.com/680649926/6a7dac0781" className="button" target="_blank">Watch How-To Video</a>
          </div>
          <ListCompare
            className={"ml-0"}
            folders={folders}
            editFolder={editFolder}
            removeFolder={removeCompare}
          />
        </div>
      </React.Fragment>
    )
  else if (loading) return <Spinner />

  return (
    <div className="image-comparison">
      {loading ? <Spinner /> : null}

      {(user && edit === null) || edit === "create-image" ? (
        <CreateImage user={user} successCreated={editFolder} editFolder={editFolder} />
      ) : null}

      {edit && edit !== "create-image" ? (
        <EditImage user={user} editId={edit} editFolder={editFolder} />
      ) : null}
    </div>
  )
}

export default UserAccountImage
