import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PageHeader from "../../components/PageHeader"
import Home from "../../components/Home"
import BuyNow from "../../components/BuyNow"
import Contact from "../../components/Contact"
import StandDesigner from "../../components/StandDesigner"
import Development from "../../components/Development"
import Applications from "../../components/Applications"
import Services from "../../components/Services"
import Faq from "../../components/Faq"
import PageContent from "../../components/PageContent"
import Cart from "../../components/Cart"
import Login from "../../components/Login"
import CheckoutLogin from "../../components/CheckoutLogin"
import Register from "../../components/Register"
import OrderPlaced from "../../components/OrderPlaced"
import CartHeader from "../../components/CartHeader"
import VFPro from "../../components/VFPro"
import Custom from "../../components/Custom"
import Education from "../../components/Education"
import Test from "../../components/Test"
import PostLink from "../../components/PostLink"

import MyAccount from "../../components/MyAccount"
import SignUp from "../../components/SignUp"
import ResetPassword from "../../components/ResetPassword"
import ForgotPassword from "../../components/ForgotPassword"

const Page = ({ data }) => {
  const { page, allGfForm, wp } = data
  const { title, content, featuredImage, excerpt, databaseId, seo } = page
  const customTemplatePages = [
	"postlink",
	"test",
    "home",
    "stand-designer",
    "development",
	"education",
    "uses",
    "services",
    "faq",
	"vf-pro",
    "contact",
    "buy-now",
	"custom-services"
  ]

  return (
    <Layout
      pageSlug={page.slug}
      bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <Seo data={seo} />

      <article
        className={`post-${databaseId} post page type-page status-publish hentry`}
        id={`post-${databaseId}`}
      >
        {page.slug !== "home" &&
		page.uri !== "/custom-services/" &&
		page.uri !== "/education/" &&
        page.uri !== "/checkout/login/" &&
        page.uri !== "/checkout/register/" &&
        page.uri !== "/checkout/cart/" &&
        page.uri !== "/my-account/" &&
        page.uri !== "/checkout/order-placed/" ? (
          <PageHeader title={title} background={featuredImage} />
        ) : (
          ""
        )}

        {page.uri === "/checkout/login/" ||
        page.uri === "/checkout/register/" ||
        page.uri === "/checkout/cart/" ||
        page.uri === "/checkout/order-placed/" ? (
          <CartHeader uri={page.uri} />
        ) : (
          ""
        )}

        {page.uri === "/" ? (
          <Home fields={page.acfHome} options={wp.acfOptionsOptions.acfOptions} />
        ) : (
          ""
        )}

        {page.uri === "/stand-designer/" ? (
          <StandDesigner fields={page.acfStandDesigner} />
        ) : (
          ""
        )}

        {page.uri === "/development/" ? (
          <Development fields={page.acfDevelopment} />
        ) : (
          ""
        )}

        {page.uri === "/uses/" ? (
          <Applications fields={page.acfApplications} />
        ) : (
          ""
        )}

        {page.uri === "/services/" ? (
          <Services fields={page.acfServices} />
        ) : (
          ""
        )}

        {page.uri === "/faq/" ? <Faq fields={page.acfFAQ} /> : ""}

        {page.uri === "/contact/" ? (
          <Contact fields={page.acfContact} forms={allGfForm} />
        ) : (
          ""
        )}
		
		{page.uri === "/vf-pro/" ? (
          <VFPro fields={page.acfVFPro}/>
        ) : (
          ""
        )}
		
				{page.uri === "/test/" ? (
          <Test forms={allGfForm}/>
        ) : (
          ""
        )}
		
		{page.uri === "/education/" ? (
          <Education fields={page.acfEducation} forms={allGfForm}/>
        ) : (
          ""
        )}
		
		{page.uri === "/custom-services/" ? (
          <Custom fields={page.acfCustom} forms={allGfForm}/>
        ) : (
          ""
        )}

        {page.uri === "/get-started/" ? <BuyNow fields={page.acfBuyNow} /> : ""}

        {page.uri === "/login/" ? <Login /> : ""}

        {page.uri === "/register/" ? <SignUp /> : ""}

        {page.uri === "/reset-password/" ? <ResetPassword /> : ""}

        {page.uri === "/forgot-password/" ? <ForgotPassword /> : ""}

        {page.uri === "/my-account/" ? <MyAccount forms={allGfForm} /> : ""}

        {page.uri === "/checkout/login/" ? <CheckoutLogin /> : ""}

        {page.uri === "/checkout/register/" ? <Register /> : ""}

        {page.uri === "/checkout/cart/" ? <Cart /> : ""}

        {page.uri === "/checkout/order-placed/" ? <OrderPlaced /> : ""}
		
		{page.uri === "/postlink/" ? <PostLink /> : ""}

        {!customTemplatePages.includes(page.slug) ? (
          <PageContent content={content} />
        ) : (
          ""
        )}
      </article>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
      template {
        templateName
      }
      slug
      uri
      featuredImage {
        node {
          sourceUrl
        }
      }
      acfHome {
        bannermain {
          button
          buttonUrl
          fieldGroupName
          image {
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          video
          subtitle
          title
        }
        text {
          button
          buttonUrl
          content
          fieldGroupName
          pretitle
          subtitle
          title
        }
        gallery {
          content
          gallery {
            type
            video
            image {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          subtitle
          title
        }
        textLeftImage {
          button
          buttonUrl
          content
          description
          image {
            sourceUrl
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          logo {
            sourceUrl
            altText
          }
          subtitle
          title
        }
        featuresText {
          button
          buttonUrl
		  button2
		  buttonUrl2
		  
          content
		  content2
          fieldGroupName
          title
        }
        textRightImage {
          content
          fieldGroupName
          subtitle
          title
          mobileVideo
          interactiveImages {
            image {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text {
                content
                top
                left
              }
            }
          }
        }
        services {
          title
          content
          button {
            title
            target
            url
          }
          video
        }
		servicetable {
		  service1 {
			title
			logo {
					sourceUrl
					altText
                	localFile {
                  		childImageSharp {
                    			fluid(maxWidth: 2200) {
                      				...GatsbyImageSharpFluid_withWebp
                    			}
                  		}
               		}
              	}	
			description
			aspects
			button
			buttonurl
		  }
		  		  service2 {
			title
				logo {
					sourceUrl
					altText
                	localFile {
                  		childImageSharp {
                    			fluid(maxWidth: 2200) {
                      				...GatsbyImageSharpFluid_withWebp
                    			}
                  		}
               		}
              	}	
			description
			aspects
			button
			buttonurl
		  }
		  		  service3 {
			title
				logo {
					sourceUrl
					altText
                	localFile {
                  		childImageSharp {
                    			fluid(maxWidth: 2200) {
                      				...GatsbyImageSharpFluid_withWebp
                    			}
                  		}
               		}
              	}	
			description
			aspects
			button
			buttonurl
		  }
		}	
      }
      acfBuyNow {
        bookADemo {
          button
          buttonUrl
          content
          title
          background {
            sourceUrl
          }
        }
        comingSoon {
          title
          content
          image {
            altText
            sourceUrl
          }
          logo {
            altText
            sourceUrl
          }
        }
        getNotified {
          content
          title
        }
        getStarted {
          discount
          logo {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            sourceUrl
          }
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            sourceUrl
          }
          pricing {
            tables {
              title
              rows {
                discount
                interval
                number
                price
                seats
                onSale
              }
            }
          }
          productFeatures {
            features {
              feature
            }
            title
          }
          subtitle
          systemRequirements {
            requirements {
              col1
              col2
              col3
            }
            title
          }
          title
        }
      }
      acfContact {
        form {
          content
          newsletterContent
          newsletterTitle
          title
        }
        locationContent {
          logo {
            altText
            sourceUrl
          }
          content
        }
        locations {
          location {
            city
            postCode
            stateShort
            streetAddress
            streetName
            streetNumber
            countryShort
            latitude
            longitude
          }
          title
          phone
        }
      }
	  acfVFPro {
		video
	  }
	  acfCustom {
		button
		buttonurl
		title
		desc
		video
		formtext
		formImage{
			sourceUrl
			localFile {
				childImageSharp {
					fluid(maxWidth: 2200) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}		
		process {
			processTitle
			processbanner {
				sourceUrl
				altText
				localFile {
					childImageSharp {
						fluid(maxWidth: 2200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
			step1
			step2
			step3
			step4
			image1{
				sourceUrl
				altText
				localFile {
					childImageSharp {
						fluid(maxWidth: 2200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
			image2{
				sourceUrl
				altText
				localFile {
					childImageSharp {
						fluid(maxWidth: 2200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
			image3{
				sourceUrl
				altText
				localFile {
					childImageSharp {
						fluid(maxWidth: 2200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}	
			image4{
				sourceUrl
				altText
				localFile {
					childImageSharp {
						fluid(maxWidth: 2200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}		
		}
		workWith {
			logosWorkedWith {
				logos {
					localFile {
                  		childImageSharp {
                    			fluid(maxWidth: 2200) {
                      				...GatsbyImageSharpFluid_withWebp
                    			}
                  		}
					}
				}
			}
		}
		examples {
			projectExamples {
				type
				image {
					localFile {
                  		childImageSharp {
                    			fluid(maxWidth: 2200) {
                      				...GatsbyImageSharpFluid_withWebp
                    			}
                  		}
               		}
				}	
				video
			}	
		}	
	  }
	  acfEducation {
		title
		intro
		logo{
				sourceUrl
				altText
				localFile {
					childImageSharp {
						fluid(maxWidth: 2200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		banner{
				sourceUrl
				altText
				localFile {
					childImageSharp {
						fluid(maxWidth: 2200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		example{
				sourceUrl
				altText
				localFile {
					childImageSharp {
						fluid(maxWidth: 2200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
			
	  }
      acfStandDesigner {
        banner2 {
          content
          background {
            id
            sourceUrl
          }
          subtitle
          title
        }
		lowerbanner {
          content
          background {
            id
            sourceUrl
          }
          subtitle
          title
        }
        capture {
          content
          image {
            id
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
		integrate {
          content
          image {
            id
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
        features {
          button
          buttonUrl
          content
          content2
          description
          logo {
            id
            altText
            sourceUrl
          }
          subtitle
          subtitle2
          title
          title2
        }
        import {
          content
          gallery {
            id
            altText
            sourceUrl
            mediaDetails {
              sizes {
                sourceUrl
              }
            }
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
        model {
          content
          gallery {
            type
            video
            image {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          title
          button
          popup {
            title
            columns {
              content
            }
          }
        }
        quantify {
          content
          gallery {
            type
            video
            image {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          title
        }
        requirements {
          button
          button2
          buttonUrl
          buttonUrl2
          content
          table {
            col1
            col2
            col3
          }
          slogan
          subtitle
          tableTitle
          title
          logo {
            id
            altText
            sourceUrl
          }
        }
        simulate {
          content
          video
          title
          video
        }
        interactiveImages {
          image {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            text {
              content
              top
              left
            }
          }
        }
      }
      acfDevelopment {
        alpine {
          button
          buttonUrl
          content
          logo {
            id
            altText
            sourceUrl
          }
          pretitle
          sideTitle
          subtitle
          title
          gallery {
            type
            video
            image {
              sourceUrl
              altText
            }
          }
        }
        banner3 {
          content
          background {
            id
            altText
            sourceUrl
          }
          title
        }
        history {
          content
          pretitle
          subtitle
          title
          logo {
            id
            altText
            sourceUrl
          }
        }
        timeline {
          content
          image {
            id
            altText
            sourceUrl
            mediaDetails {
              sizes {
                sourceUrl
              }
            }
          }
          title
          video
          year
        }
		buttons {
			buttonbackground {
				id
				altText
				sourceUrl					
				localFile {
                  		childImageSharp {
                    			fluid(maxWidth: 2200) {
                      				...GatsbyImageSharpFluid_withWebp
								}
						}
					}		
			}
		}
      }
      acfApplications {
        applications {
          content
          content2
          content3
          pretitle
          title
          title2
          title3
        }
        caseStudy {
          button
          buttonUrl
          content
          beforeAfterEmbed
          before {
            id
            altText
            sourceUrl
          }
          after {
            id
            altText
            sourceUrl
          }
          title
        }
        visualization {
          content
          pretitle
          subtitle
          title
        }
      }
      acfServices {
        heading {
          content
          fieldGroupName
          pretitle
          subtitle
          title
        }
        imageText {
          button
          buttonUrl
          content
          fieldGroupName
          leftImage
          title
        }
        servicesGroup {
          content
          content2
          fieldGroupName
          pretitle
          title
          title2
        }
      }
      acfFAQ {
        button
        buttonUrl
        faqs {
          answer
          question
        }
      }
      seo {
        title
        twitterDescription
        twitterImage {
          sourceUrl
        }
        metaKeywords
        metaDesc
        opengraphType
        twitterTitle
        opengraphUrl
        opengraphTitle
        opengraphSiteName
        opengraphAuthor
      }
    }
    allGfForm {
      nodes {
        apiURL
        formId
        formFields {
          id
          placeholder
          label
          type
          inputName
          content
          choices
          isRequired
        }
        button {
          imageUrl
          text
          type
        }
        confirmations {
          message
        }
        is_active
        slug
      }
    }
    wp {
      acfOptionsOptions {
        acfOptions {
          copy
          top {
            button
            buttonUrl
            content
            content2
            form
            title
            title2
          }
          bottom {
            button
            buttonUrl
            content
            content2
            content3
            title
            title2
            title3
            logo {
              sourceUrl
              altText
            }
            logo2 {
              sourceUrl
              altText
            }
          }
          social {
            icon {
              id
              sourceUrl
              altText
            }
            url
          }
          video
          socialTitle
          content
          showPopup
        }
      }
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
