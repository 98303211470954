import React, {useState} from "react"
import UploadFiles from "./UploadFiles"
import { uploadImage } from "../../../service/fire-base-image"
import Spinner from "../../Spinner"
import ArrowSvg from "../../../assets/images/arrow.svg"
import firebase from "gatsby-plugin-firebase"

const formatDate = () => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  const today = new Date()
  return today.toLocaleDateString("en-US", options)
}
const CreateImage = ({ user, successCreated, editFolder }) => {
  const [loading, setLoading] = useState(false);

  const publishFiles = async ({ files, metaData }) => {
    setLoading(true)

    metaData = { ...metaData, date: formatDate() }
    const timestamp = new Date().getTime()

    await uploadImage(
      files,
      `${user.uid}/${timestamp}`,
      metaData
    );

    // strore to db
    await firebase.firestore().collection('comparisons').doc(timestamp.toString()).set({
      userid: user.uid
    })
    .then((response) => {
      console.log("Document successfully written!")
    })
    .catch((error) => {
      console.error("Error writing document: ", error)
    })

    setLoading(false);
    successCreated(null);
  }

  return (
    <div>
      { loading ? <Spinner /> : null}
      <span
        className={"arrow-back"}
        onClick={() => {
          editFolder(null)
        }}
      >
        <img src={ArrowSvg} alt=""/>
      </span>
      <UploadFiles publishFiles={publishFiles}>Publish</UploadFiles>
    </div>
  )
}
export default CreateImage
