import React from 'react'
import ReactPlayer from 'react-player/lazy'

const Services = ({ fields }) => {
  console.log(fields)

  return (
    <React.Fragment>
      {fields.heading ? (
        <section className="dev-visualization">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="multi-title">
                  <span>{fields.heading.pretitle}</span>
                  <h2>
                    {fields.heading.title}
                    <strong>{fields.heading.subtitle}</strong>
                  </h2>
                </div>
              </div>
              <div className="col">
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.heading.content }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields.servicesGroup ? (
        <section className="dev-applications services">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="pre-title" dangerouslySetInnerHTML={{ __html: fields.servicesGroup.pretitle }}></h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3 dangerouslySetInnerHTML={{ __html: fields.servicesGroup.title }}></h3>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.servicesGroup.content }}></div>
              </div>
              <div className="col">
                <h3 dangerouslySetInnerHTML={{ __html: fields.servicesGroup.title2 }}></h3>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.servicesGroup.content2 }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields.imageText ? (
        <section className="dev-case-study services">

            <div className="row">
              <div className="col">
                <div className="image-wrap">
                  <ReactPlayer url={fields.imageText.leftImage} className='react-player' width='100%' height='100%' loop={false} controls={true} />
                </div>              
              </div>
              <div className="col">
                <div className="content-wrapper">
                  <h2 dangerouslySetInnerHTML={{ __html: fields.imageText.title }}></h2>
                  <div className="content" dangerouslySetInnerHTML={{ __html: fields.imageText.content }}></div>
                  <div className="button-wrap">
                    <a href={fields.imageText.buttonUrl} className="button button-light">{fields.imageText.button}</a>
                  </div>
                </div>
              </div>
            </div>

        </section>
      ) : ''}
    </React.Fragment>
  );
}

export default Services
