import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Background from "../assets/images/headerbig.jpg"

const PageHeader = ({ title, background, button }) => {
  console.log(background);
  const backgroundImage = (background && background.node && background.node.sourceUrl) ? background.node.sourceUrl : Background
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsOptions {
          acfOptions {
            supportButton
            supportButtonUrl
          }
        }
      }
    }
  `)

  let slug = '/';
  if (typeof window !== 'undefined' && window.location) {
    const paths = window.location.pathname.split('/')
    slug = (paths[1]) ? paths[1] : '/';
  }
  const showOnPages = ['faq', 'contact']
  const supportButton = (wp.acfOptionsOptions.acfOptions.supportButton && wp.acfOptionsOptions.acfOptions.supportButtonUrl) ? {button: wp.acfOptionsOptions.acfOptions.supportButton, url: wp.acfOptionsOptions.acfOptions.supportButtonUrl} : {button: 'Contact Support', url: 'mailto:support@alpine.com'}

  return (
 <div className="page-header" style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className="container">
        <h6 dangerouslySetInnerHTML={{ __html: title }}></h6>

        {supportButton.button && supportButton.url && showOnPages.includes(slug) ? (
          <div className="button-wrap show-desktop">
            <a href={supportButton.url} className="button button-light filled">{supportButton.button}</a>
          </div>
        ) : ''}
      </div>
    </div>
  )
}

export default PageHeader
