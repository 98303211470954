import {useEffect, useReducer, useState} from "react";

export default function useCartState() {
    
    const [cart, setCart] = useState({
        quantity: null,
        price: null,
        interval: null,
        savings: null,
        amountOff: null,
        percentOff: null
    });

    useEffect(() => {
        if (typeof window != undefined) {
          const cart = window.localStorage.getItem('vf-cart') ? JSON.parse(window.localStorage.getItem('vf-cart')) : null 
          if (cart) {
            setCart(() => ({quantity: cart.quantity, price: cart.price, interval: cart.interval, savings: cart.savings}))
          }
        }
    }, [])

    const increment = () => {
        calculatePrice(cart.interval, cart.quantity + 1)
    }
    
    const decrement = () => {
        calculatePrice(cart.interval, cart.quantity - 1)
    }

    const addDiscount = (data) => {
      // console.log(data)
      setCart({...cart, amountOff: data.amount_off ? data.amount_off : null, percentOff: data.percent_off ? data.percent_off : null })
    }

    const calculatePrice = (interval, quantity) => {
      let price
      let savings
      if (quantity >= 10) {
        // Yearly 89
        // Monthly 19
        price = interval === 'yearly' ? 69 : 15
        savings = interval === 'yearly' ? 20 : 4
      } else if (quantity > 1 && quantity < 10) {
        price = interval === 'yearly' ? 79 : 17
        savings = interval === 'yearly' ? 10 : 2
      } else if (quantity === 1) {
        price = interval === 'yearly' ? 89 : 19
        savings = interval === 'yearly' ? 0 : 0
      } else {
        interval = ''
        quantity = 0
        price = 89
        savings = 0
      }
      const finalSavings = savings > 0 ? savings * quantity : 0
      setCart({ ...cart, price: price, quantity: +quantity, interval: interval, savings: finalSavings })
    }
    
    return [cart, increment, decrement, addDiscount]
}
