import { navigate } from "gatsby"
import React, { Component, useState } from "react"
import NewsletterSignUp from "./NewsletterSignUp"

class BuyNow extends Component {
  constructor(props) {
    super(props)
    const { fields } = props
    console.log(fields)
    this.state = {
      fields: fields,
      price: 89,
      quantity: 0,
      savings: 0,
      interval: "",
    }
  }

  componentDidMount() {
    // if (typeof window != undefined) {
    //   const cart = localStorage.getItem('vf-cart');
    //   if (cart) {
    //     const { quantity, interval } = JSON.parse(cart)
    //     this.calculatePrice(interval, quantity)
    //   }
    // }
  }

  handleChange = (ev) => {
    const interval = ev.target.id
    const quantity = ev.target.value ? ev.target.value : 0
    this.calculatePrice(interval, +quantity)
  }

  addToCart = () => {
    if (typeof window != undefined) {
      const { price, quantity, interval, savings } = this.state
      localStorage.setItem(
        "vf-cart",
        JSON.stringify({
          price: price,
          quantity: quantity,
          interval: interval,
          savings: savings,
        })
      )
      navigate("/checkout/cart")
    }
  }

  calculatePrice = (interval, quantity) => {
    let price
    let savings
    if (quantity >= 10) {
      // Yearly 89
      // Monthly 19
      price = interval === 'yearly' ? 69 : 15
      savings = interval === 'yearly' ? 20 : 4
    } else if (quantity > 1 && quantity < 10) {
      price = interval === 'yearly' ? 79 : 17
      savings = interval === 'yearly' ? 10 : 2
    } else if (quantity === 1) {
      price = interval === 'yearly' ? 89 : 19
      savings = interval === 'yearly' ? 0 : 0
    } else {
      interval = ''
      quantity = 0
      price = 89
      savings = 0
    }
    const finalSavings = savings > 0 ? savings * quantity : 0
    this.setState({
      price: price,
      quantity: +quantity,
      interval: interval,
      savings: finalSavings,
    })
  }

  render() {
    return (
      <div className="buy-now-wrap">
        {this.state.fields.comingSoon ? (
          <div className="buy-now-coming-soon">
            <div className="container">
              <div className="title-wrap">
                {/* <h2 dangerouslySetInnerHTML={{ __html: fields.comingSoon.title }}></h2> */}
                <h2>
                  {this.state.fields?.getStarted?.title}{" "}
                  <strong>{this.state.fields?.getStarted?.subtitle}</strong>
                </h2>
              </div>
              <div className="row">
                <div className="col">
                  <div className="image">
                    <img
                      src={this.state.fields?.getStarted?.image?.sourceUrl}
                      alt={this.state.fields?.getStarted?.image?.altText}
                    />
                  </div>
                  <div className="info-boxes">
                    {this.state.fields?.getStarted?.pricing?.tables &&
                      this.state.fields?.getStarted?.pricing?.tables.map(
                        (table, i) => {
                          return (
                            <div className="box" key={`table-${i}`}>
                              <h3>{table?.title}</h3>
                              {table?.rows &&
                                table?.rows.map((row, index) => {
                                  return (
                                    <div className="row" key={`price-${index}`}>
                                      <div>
                                        <strong>{row?.number}</strong>
                                        <span> {row?.seats}</span>
                                      </div>
                                      <div>
                                          {row.onSale ? (
                                            <strong>
                                              <del>{row?.price}</del>{" "}
                                              <span
                                                style={{
                                                  color: "rgba(255, 10, 10, 0.8)",
                                                }}
                                              >
                                                {row?.discount}
                                              </span>

                                            </strong>
                                          ) : (
                                            <strong>
                                              {row?.price}
                                            </strong>
                                          )}
                                        <span> {row?.interval}</span>
                                      </div>
                                    </div>
                                  )
                                })}
                            </div>
                          )
                        }
                      )}
                  </div>
                  {this.state.fields?.getStarted?.discount ? (                    
                    <div className="row message">
                      <div className="col">
                        <h4>{this.state.fields?.getStarted?.discount}</h4>
                      </div>
                    </div>
                  ) : ''}
                </div>

                <div className="col">
                  <img
                    className="logo"
                    src={this.state.fields?.getStarted?.logo?.sourceUrl}
                    alt={this.state.fields?.getStarted?.logo?.altText}
                  />
                </div>
                <div className="col">
                  <div className="quantity-options">
                    <div className="option">
                      <div>
                        <strong>Yearly</strong>
                      </div>
                      <div>
                        <label>Qty</label>
                        <input
                          type="number"
                          placeholder="0"
                          id="yearly"
                          onChange={(ev) => this.handleChange(ev)}
                          value={
                            this.state.interval === "yearly"
                              ? this.state.quantity
                              : ""
                          }
                        ></input>
                      </div>
                      <div>
                        <div className="row">
                          <span className="current-price">
                            $
                            {this.state.interval === "yearly"
                              ? this.state.price
                              : "89"}
                          </span>
                          {this.state.interval === "yearly" &&
                          this.state.quantity >= 1 ? (
                            <del>$89</del>
                          ) : (
                            ""
                          )}
                          <span className="per">(per seat/yr)</span>
                        </div>

                        {this.state.interval === "yearly" &&
                        this.state.quantity >= 1 ? (
                          <div className="savings row">
                            Savings: ${this.state.savings}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="option">
                      <div>
                        <strong>Monthly</strong>
                      </div>
                      <div>
                        <label>Qty</label>
                        <input
                          type="number"
                          placeholder="0"
                          id="monthly"
                          onChange={(ev) => this.handleChange(ev)}
                          value={
                            this.state.interval === "monthly"
                              ? this.state.quantity
                              : ""
                          }
                        ></input>
                      </div>
                      <div>
                        <div className="row">
                          <span className="current-price">
                            $
                            {this.state.interval === "monthly"
                              ? this.state.price
                              : "19"}
                          </span>
                          {this.state.interval === "monthly" &&
                          this.state.quantity >= 1 ? (
                            <del>$19</del>
                          ) : (
                            ""
                          )}
                          <span className="per">(per seat/mo)</span>
                        </div>
                        {this.state.interval === "monthly" &&
                        this.state.quantity >= 1 ? (
                          <div className="savings row">
                            Savings: ${this.state.savings}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="add-to-cart">
                      <button
                        className="button button-dark"
                        disabled={
                          !this.state.quantity || this.state.quantity < 1
                        }
                        onClick={(ev) => this.addToCart(ev)}
                      >
                        Add to cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <BuyNowTabs
          features={this.state.fields?.getStarted?.productFeatures}
          requirements={this.state.fields?.getStarted?.systemRequirements}
        />
      </div>
    )
  }
}

const BuyNowTabs = (data) => {
  const [activeTab, setActiveTab] = useState(0)

  const changeTab = (index) => {
    setActiveTab(index)
  }

  return (
    <React.Fragment>
      <div className="buy-now-tabs">
        <div className="container">
          <button
            className={`tab ${activeTab === 0 ? "active" : ""}`}
            onClick={() => changeTab(0)}
          >
            {data?.features?.title}
          </button>
          <button
            className={`tab ${activeTab === 1 ? "active" : ""}`}
            onClick={() => changeTab(1)}
          >
            {data?.requirements?.title}
          </button>
          {/* <button className={`tab ${activeTab === 2 ? 'active' : ''}`} onClick={() => changeTab(2)}>
            Reviews
          </button> */}
        </div>
      </div>
      <div className="buy-now-tab-content">
        <div className={`content ${activeTab === 0 ? "active" : ""}`}>
          <div className="container">
            <ul>
              {data?.features?.features &&
                data?.features?.features.map((feature, index) => {
                  return <li key={index}>{feature?.feature}</li>
                })}
            </ul>
          </div>
        </div>
        <div className={`content ${activeTab === 1 ? "active" : ""}`}>
          <div className="container">
            <table>
              <tbody>
                {data?.requirements?.requirements &&
                  data?.requirements?.requirements.map((row, index) => {
                    return (
                      <tr key={`requirements-${index}`}>
                        <td>{row?.col1}</td>
                        <td>{row?.col2}</td>
                        <td>{row?.col3}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={`content ${activeTab === 2 ? "active" : ""}`}>
          <div className="container">Reviews tab</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BuyNow
