
import React from 'react'

const Test = ({ fields, forms }) => {
  return (

						
			<div>
			<p>test2</p>
			</div>

	 
  );
}

export default Test
