import React, { useEffect, useState } from 'react'
import StandDesignerIcon from '../assets/svg/stand-designer-icon.svg'
import CloseIcon  from '../assets/svg/close_icon.svg'
import { navigate } from 'gatsby'
import firebase from 'gatsby-plugin-firebase'
import useAuthState from '../functions/useAuthState'
import useCartState from '../functions/useCartState'

const Cart = () => {

  const [cart, increment, decrement] = useCartState()
  const [user, loading, error] = useAuthState(firebase)

  const proceed = () => {
    if (typeof window != undefined) {
      const { price, quantity, interval, savings } = cart
      window.localStorage.setItem('vf-cart', JSON.stringify({price: price, quantity: quantity, interval: interval, savings: savings}))
      if (user) {
        navigate('/checkout/register')
      } else {
        navigate('/checkout/login')
      }
    }
  }

  return (
    <React.Fragment>
      <section className="cart">
        <div className="container">

          <div className="row cart-table-header show-desktop flex">
            <div className="col">
              <h6>Product</h6>
            </div>
            <div className="col">
              <h6>Commitment</h6>
            </div>
            <div className="col">
              <h6># of Seats</h6>
            </div>
            <div className="col">
              <h6>Subtotal</h6>
            </div>
            <div className="col">
              <h6>Total</h6>
            </div>
          </div>

          <hr className="show-desktop block" />

          <div className="row cart-details">
            <div className="col">
              <div className="product">
                <img src={StandDesignerIcon}/>
                <span>Stand Designer</span>
              </div>
            </div>
            <div className="col prod-details">
              <span className="interval-label">{cart.interval} Plan</span>
            </div>
            <div className="col prod-details">
              <button className="button-circle" disabled={cart.quantity === 1} onClick={decrement}>-</button>
              <span className="quantity">
                {cart.quantity}
              </span>
              <button className="button-circle" onClick={increment}>+</button>
            </div>
            <div className="col prod-details">
              <span>${cart.price}</span>
            </div>
            <div className="col prod-details">
              <span>${cart.price * cart.quantity}</span>
              <span className="tax">+ tax</span>
            </div>
          </div>

          <hr/>
          <div className="button-wrapper">
            <button className="button button-dark" onClick={proceed}>Proceed to Checkout</button>
          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default Cart