import React from 'react'
import Timeline from "./Timeline"
import Gallery from './Gallery'

const Development = ({ fields }) => {
  // console.log(fields)

  return (
    <React.Fragment>
      {fields?.history ? (
        <section className="dev-history">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="logo">
                  <img src={fields?.history?.logo?.sourceUrl} alt={fields?.history?.logo?.altText} />
                </div>
              </div>
              <div className="col">
                <div className="content" dangerouslySetInnerHTML={{ __html: fields?.history?.content }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields?.timeline ? (
        <Timeline images={fields?.timeline} />
      ) : ''}
	  
	{fields?.buttons ? (
<section className="button_background show-desktop" style={{backgroundImage: `url(${fields?.buttons?.buttonbackground?.sourceUrl})`}}>  
<div className="row">
	<div className="column">
		<a href="/faq/"	type="button" className="button button-round button-primary">FAQ</a>
	</div>
	<div className="column">
		<a href="/contact/"	type="button" className="button button-round button-primary">Contact Us</a>
	</div>
</div>
</section>
) : ''}

	{fields?.buttons ? (
<section className="button_background show-mobile" style={{backgroundImage: `url(${fields?.buttons?.buttonbackground?.sourceUrl})`}}>  
<div className="row-mobile">
	<div className="col-mobile1">
		<a href="/faq/"	type="button" className="button button-round button-primary">FAQ</a>
	</div>
	<div className="col-mobile2">
		<a href="/contact/"	type="button" className="button button-round button-primary">Contact Us</a>
	</div>
</div>
</section>
) : ''}




      {fields?.alpine ? (
        <section className="dev-alpine">
          <div className="container">
            <div className="row">
              <div className="col">
                { /*<div className="logo">
                  <img src={fields?.alpine.logo.sourceUrl} alt={fields?.alpine.logo.altText} />
                  <div className="multi-title">
                    <span>{fields?.alpine.pretitle}</span>
                  </div>
                </div>*/ }
                <div className="multi-title">
                  <h2>
                    {fields?.alpine?.title}
                    <strong>{fields?.alpine?.subtitle}</strong>
                  </h2>
                </div>
                <Gallery items={fields?.alpine?.gallery} />
                <div className="button-wrap show-desktop">
                  <a href={fields?.alpine?.buttonUrl} className="button" target="_blank">{fields?.alpine?.button}</a>
                </div>
              </div>
              <div className="col">
                <div className="side-title" dangerouslySetInnerHTML={{ __html: fields?.alpine?.sideTitle }}></div>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields?.alpine?.content }}></div>
				<div className="button-wrap show-mobile">
                  <a href={fields?.alpine?.buttonUrl} className="button">{fields?.alpine?.button}</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}
	  
	  

      {fields?.banner3 ? (
        <section className="dev-banner" style={{backgroundImage: `url(${fields?.banner3?.background?.sourceUrl})`}}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="multi-title">
                  <h2>
                    {fields?.banner3?.title}
                    <strong>{fields?.banner3?.subtitle}</strong>
                  </h2>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields?.banner3?.content }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}
    </React.Fragment>
  );
}

export default Development
