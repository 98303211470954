import React from "react"
import { Link } from "gatsby"
const host = process.env.WP_BASE_URL

const ViewComparisons = ({ folder }) => {

  return (
    <div className="view-comparisons">
      <h2>View Comparisons</h2>
      <div className="txt">
        To compare your images, copy the code in the box below and paste it in
        your browsers URL line. If you update the settings, don't forget to
        click "PUBLISH" again
      </div>
      <div className="links-images">
        <Link to={`/image-compare/${folder}`}>
          {`${host.replace('api.', 'https://')}/image-compare-${folder}`}
        </Link>
      </div>
    </div>
  )
}

export default ViewComparisons
