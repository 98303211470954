import React from "react"
import PropTypes from "prop-types"

const InputLabel = ({ handle, property, value = '' }) => {
  const changeValue = (val) => handle(val, property)
  return (
    <div className="label">
      <label>
        <span>Title</span>
        <input
          type="text"
          defaultValue={value}
          onChange={(e) => {
            changeValue(e.target.value)
          }}
        />
      </label>
    </div>
  )
}

InputLabel.propsType = {
  handle: PropTypes.func.isRequired,
  property: PropTypes.any,
}

export default InputLabel
