import React from 'react'

const Applications = ({ fields }) => {
  // console.log(fields)

  return (
    <React.Fragment>
      {fields.visualization ? (
        <section className="dev-visualization">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="multi-title">
                  <span>{fields.visualization.pretitle}</span>
                  <h2>
                    {fields.visualization.title}
                    <strong>{fields.visualization.subtitle}</strong>
                  </h2>
                </div>
              </div>
              <div className="col">
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.visualization.content }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields.applications ? (
        <section className="dev-applications">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="pre-title" dangerouslySetInnerHTML={{ __html: fields.applications.pretitle }}></h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3 dangerouslySetInnerHTML={{ __html: fields.applications.title }}></h3>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.applications.content }}></div>
              </div>
              <div className="col">
                <h3 dangerouslySetInnerHTML={{ __html: fields.applications.title2 }}></h3>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.applications.content2 }}></div>
              </div>
              <div className="col">
                <h3 dangerouslySetInnerHTML={{ __html: fields.applications.title3 }}></h3>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.applications.content3 }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields.caseStudy ? (
        <section className="dev-case-study">

            <div className="row">
              <div className="col">
                <span className="before">Before</span>
                <span className="after">After</span>
                <div className="iframe-wrapper" dangerouslySetInnerHTML={{ __html: fields.caseStudy.beforeAfterEmbed }}></div>
                {/* <div className="image-wrap">
                  <div className="label">Before</div>
                  <img src={fields.caseStudy.before.sourceUrl} alt={fields.caseStudy.before.altText} />
                </div>
                <div className="image-wrap">
                  <div className="label">After</div>
                  <img src={fields.caseStudy.after.sourceUrl} alt={fields.caseStudy.after.altText} />
                </div> */}
              
              </div>
              <div className="col">
                <div className="content-wrapper">
                  <h2 dangerouslySetInnerHTML={{ __html: fields.caseStudy.title }}></h2>
                  <div className="content" dangerouslySetInnerHTML={{ __html: fields.caseStudy.content }}></div>
                  <div className="button-wrap">
                    <a href={fields.caseStudy.buttonUrl} className="button button-light">{fields.caseStudy.button}</a>
                  </div>
                </div>
              </div>
            </div>

        </section>
      ) : ''}
    </React.Fragment>
  );
}

export default Applications
