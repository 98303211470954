import React from 'react'
import ChevronIcon from '../assets/svg/chevron_icon.svg'

const CartHeader = ({ uri }) => {
  return (
    <div className="cart-header">
      <div className="cart-steps">
        <span className={uri === '/checkout/cart/' ? 'active' : ''}>CART</span>
        <img src={ChevronIcon}/>
        <span className={uri === '/checkout/register/' ? 'active' : ''}>REGISTER & PAY</span>
        <img src={ChevronIcon}/>
        <span className={uri === '/checkout/order-placed/' ? 'active' : ''}>ORDER PLACED</span>
      </div>
    </div>
  )
}

export default CartHeader;