import React from 'react'

const PageContent = ({ content }) => {
  // console.log(content)

  return (
    <React.Fragment>
      {content ? (
        <section className="page-content">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}
    </React.Fragment>
  );
}

export default PageContent
