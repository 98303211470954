import React, { Component } from 'react'
import ReactPlayer from 'react-player/lazy'

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import Img from "gatsby-image"

import { InView } from 'react-intersection-observer';

class Gallery extends Component {

    constructor(props) {
        super(props)
        this.state = {
            items: props.items,
            modal: false
        }
    }

    toggleModal(index) {
      const modal = this.state.modal
      if (modal === index) {
        this.setState(state => ({modal: false}))
      } else {
        this.setState(state => ({modal: index}))
      }
    }

    // dir="rtl"

    render() {
        return (
            <InView key="main">
              {({ inView, ref, entry }) => {
                return (
                  <div className={`gallery-wrap ${inView ? 'animationStart' : ''}`} ref={ref}>
                      <div className="gallery-new show-desktop">
                          <Swiper
                              slidesPerView={3}
                              spaceBetween={5}
                              dir="rtl"
                              navigation
                          >
                              {this.state.items && this.state.items.length > 0 && this.state.items.map((item, index) => {
                                  return (
                                      <SwiperSlide key={index}>
                                          {item.type === 'image' ? (
                                              <div key={index} className="image-container">
                                                  {item.image.localFile ? (
                                                    <Img
                                                      fluid={item.image.localFile.childImageSharp.fluid}
                                                      objectFit="cover"
                                                      objectPosition="50% 50%"
                                                      alt={item.image.altText}
                                                    />
                                                  ) : (
                                                    <img src={item.image.sourceUrl} alt={item.image.altText} />
                                                  )}
                                              </div>
                                          ) : (
                                              <InView key={index}>
                                                  {({ inView, ref, entry }) => {
                                                      // console.log(inView);
                                                      return (
                                                          <div key={index} className="video-container" ref={ref} onClick={() => {this.toggleModal(index)}}>
                                                              <div className="play-wrap">
                                                                <div className="play">▶</div>
                                                              </div>
                                                              <ReactPlayer url={item.video} className='react-player' width='100%' height='100%' playing={false} loop={false} volume={0} muted={true} />
                                                          </div>
                                                  )}}
                                              </InView>
                                          )}
                                      </SwiperSlide>
                                  )
                              })}
                          </Swiper>
                      </div>

                      <div className="gallery-slider show-mobile">
                          <Swiper
                              slidesPerView={2}
                              spaceBetween={5}
                              navigation
                          >
                              {this.state.items && this.state.items.length > 0 && this.state.items.map((item, index) => {
                                  return (
                                      <SwiperSlide key={index}>
                                          {item.type === 'image' ? (
                                              <div key={index} className="image-container">
                                                  <img src={item.image.sourceUrl} alt={item.image.altText} />
                                              </div>
                                          ) : (
                                              <InView key={index}>
                                                  {({ inView, ref, entry }) => {
                                                      return (
                                                          <div key={index} className="video-container" ref={ref} onClick={() => {this.toggleModal(index)}}>
                                                              <div className="play-wrap">
                                                                <div className="play">▶</div>
                                                              </div>
                                                              <ReactPlayer url={item.video} className='react-player' width='100%' height='100%' playing={false} loop={false} volume={0} muted={true} />
                                                          </div>
                                                  )}}
                                              </InView>
                                          )}
                                      </SwiperSlide>
                                  )
                              })}
                          </Swiper>
                      </div>

                      {this.state.items && this.state.items.length > 0 && this.state.items.map((item, index) => {
                        return (
                          <div className="modals" key={'modal-' + index}>
                            {item.video ? (
                              <div className={(this.state.modal !== index) ? 'modal video-modal hidden' : 'modal video-modal'}>
                                <div className="modal-outside" onClick={() => {this.toggleModal(index)}}></div>
                                <div className="modal-wrap dark">
                                  <button className="close" onClick={() => {this.toggleModal(index)}}>x</button>
                                  <div className="video-wrap">
                                    <ReactPlayer url={item.video} className='react-player' width='100%' height='100%' playing={(this.state.modal === index)} loop={false} controls={true} />
                                  </div>
                                </div>
                              </div>
                            ) : ''}
                          </div>
                        )
                      })}
                  </div>
                )
              }}
            </InView>
        )
    }
}

export default Gallery
