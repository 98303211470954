import React, { useState, useRef, useCallback } from 'react'
import ReactPlayer from 'react-player'
import Gallery from './Gallery'
import InterActiveImages from './InteractiveImages'

import { InView } from 'react-intersection-observer';
import Img from 'gatsby-image';
import ReadMore from './ReadMore';

const StandDesigner = ({ fields }) => {
  const [modal, setModal] = useState(false);
  console.log(fields)

  return (
    <React.Fragment>
      {fields.features ? (
        <section className="stand-features">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="logo">
                  <img src={fields.features.logo.sourceUrl} alt={fields.features.logo.altText} />
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.features.description }}></div>
                <div className="button-wrap">
                  <a href={fields.features.buttonUrl} className="button button-light-bg">{fields.features.button}</a>
                </div>
              </div>
              <div className="col">
                <div className="multi-title">
                  <h2>
                    {fields.features.title}
                    <strong>{fields.features.subtitle}</strong>
                  </h2>
                </div>
                <div className="content show-desktop" dangerouslySetInnerHTML={{ __html: fields.features.content }}></div>
                <div className="show-mobile">
                  <ReadMore className="show-mobile" content={fields.features.content} />
                </div>
                <div className="multi-title">
                  <h2>
                    {fields.features.title2}
                    <strong>{fields.features.subtitle2}</strong>
                  </h2>
                </div>
                <div className="content show-desktop" dangerouslySetInnerHTML={{ __html: fields.features.content2 }}></div>
                <div className="show-mobile">
                  <ReadMore className="show-mobile" content={fields.features.content2} />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields.banner2 ? (
        <section className="stand-banner" style={{backgroundImage: `url(${fields.banner2.background.sourceUrl})`}}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="multi-title">
                  <h2>
                    {fields.banner2.title}
                    <strong>{fields.banner2.subtitle}</strong>
                  </h2>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.banner2.content }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields.model ? (
        <section className="stand-model">
          <div className="container">
            <div className="row">
              <div className="col">
                <Gallery items={fields.model.gallery} />
              </div>
              <div className="col">
                <h2 dangerouslySetInnerHTML={{ __html: fields.model.title }}></h2>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.model.content }}></div>
                <div className="button-wrap">
                  <button className="button" onClick={() => setModal(true)}>{fields.model.button}</button>
                </div>

                {fields.model.popup ? (
                  <div className={!modal ? 'modal hidden' : 'modal'}>
                    <div className="modal-outside" onClick={() => setModal(false)}></div>
                    <div className="modal-wrap">
                      <button className="close" onClick={() => setModal(false)}>x</button>
                      <h3>{fields.model.popup.title}</h3>
                      <div className="container">
                        <div className="row">
                          {fields.model.popup.columns && fields.model.popup.columns.length > 0 && fields.model.popup.columns.map((col, index) => {
                            return (
                              <div key={index} className="col">
                                <div className="content" dangerouslySetInnerHTML={{ __html: col.content }}></div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : ''}
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields.import ? (
        <section className="stand-import">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 dangerouslySetInnerHTML={{ __html: fields.import.title }}></h2>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.import.content }}></div>
              </div>
              <div className="col">
                <div className="gallery">
                  {fields.import.gallery.length > 0 && fields.import.gallery.map((image, index) => {

                    const thumb = (image.mediaDetails.sizes && image.mediaDetails.sizes[3]) ? image.mediaDetails.sizes[3].sourceUrl : image.sourceUrl
                    return (
                      <InView key={index}>
                        {({ inView, ref, entry }) => {
                          return (
                            <div ref={ref} key={image.id} className={`image-wrap ${inView ? 'animationStart' : ''}`} style={{animationDelay: (index + 1) * 400 + 'ms'}}>
                                <img alt={image.altText} src={thumb} />
                                <div className="separator"></div>
                            </div>
                          )
                        }}
                      </InView>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}

      {fields.quantify ? (
        <section className="stand-quantify">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 dangerouslySetInnerHTML={{ __html: fields.quantify.title }}></h2>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.quantify.content }}></div>
              </div>
              <div className="col">
                <Gallery items={fields.quantify.gallery} />
              </div>
            </div>
          </div>
        </section>
      ) : ''}
	  
	 {fields.integrate ? (
        <section className="stand-capture">
          <div className="container">
			<div className="show-desktop">
				<div className="row">
					<div className="col">
						<h2 dangerouslySetInnerHTML={{ __html: fields.integrate.title }}></h2>
						<div className="content" dangerouslySetInnerHTML={{ __html: fields.integrate.content }}></div>
					</div>
              <div className="col">
				<div className="image-wrap">
                  <Img
                    fluid={fields.integrate.image.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={fields.integrate.image.altText}
                  />
                </div>
              </div>
            </div>
			</div>
			
			 <div className="show-mobile">

              <div className="row-mob-1">
                <h2 dangerouslySetInnerHTML={{ __html: fields.integrate.title }}></h2>
			</div>
			<div className="row-mob-1">
				<div className="content" dangerouslySetInnerHTML={{ __html: fields.integrate.content }}></div>
			</div>
              <div className="row">
				<div className="image-wrap">
                  <Img
                    fluid={fields.integrate.image.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={fields.integrate.image.altText}
                  />
                </div>
              </div>
			
          </div>
		  </div>
        </section>
      ) : ''}


      {fields.simulate ? (
        <section className="stand-simulate">
          <div className="container">
            <div className="row">
			
              <div className="col">
			  <div className="show-desktop">
                <InView>
                  {({ inView, ref, entry }) => {
                    return (
                      <div className="video-wrap" ref={ref}>
                        <ReactPlayer url={fields.simulate.video} className='react-player' width='100%' height='100%' playing={inView ? true : false} loop={false} controls={true} volume={0} muted={true} />
                      </div>
                    )
                  }}
                </InView>
              </div>
			  </div>
              <div className="col">
			                  <h2 dangerouslySetInnerHTML={{ __html: fields.simulate.title }}></h2>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.simulate.content }}></div>
              </div>
			  			<div className="show-mobile">
              <div className="col">
                <InView>
                  {({ inView, ref, entry }) => {
                    return (
                      <div className="video-wrap" ref={ref}>
                        <ReactPlayer url={fields.simulate.video} className='react-player' width='100%' height='100%' playing={inView ? true : false} loop={false} controls={true} volume={0} muted={true} />
                      </div>
                    )
                  }}
                </InView>
              </div>
			  </div>
            </div>
          </div>
        </section>
      ) : ''}

     	 {fields.capture ? (
        <section className="stand-capture">
          <div className="container">
			<div className="show-desktop">
				<div className="row">
					<div className="col">
						<h2 dangerouslySetInnerHTML={{ __html: fields.capture.title }}></h2>
						<div className="content" dangerouslySetInnerHTML={{ __html: fields.capture.content }}></div>
					</div>
              <div className="col">
				<div className="image-wrap">
                  <Img
                    fluid={fields.capture.image.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={fields.capture.image.altText}
                  />
                </div>
              </div>
            </div>
			</div>
			
			 <div className="show-mobile">

              <div className="row-mob-1">
                <h2 dangerouslySetInnerHTML={{ __html: fields.capture.title }}></h2>
			</div>
			<div className="row-mob-1">
				<div className="content" dangerouslySetInnerHTML={{ __html: fields.capture.content }}></div>
			</div>
              <div className="row">
				<div className="image-wrap">
                  <Img
                    fluid={fields.capture.image.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={fields.capture.image.altText}
                  />
                </div>
              </div>
			
          </div>
		  </div>
        </section>
      ) : ''}
	  
	  {fields.lowerbanner ? (
        <section className="stand-lowerbanner" style={{backgroundImage: `url(${fields.lowerbanner.background.sourceUrl})`}}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="multi-title">
                  <h2>
                    {fields.lowerbanner.title}
                    <strong>{fields.lowerbanner.subtitle}</strong>
                  </h2>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.lowerbanner.content }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : ''}
	  
	 <div className="show-desktop">
	 
	<div className="interactive-image">
			<div className="row">
				<h1>Stand Designer Interactive Tour</h1>
			</div>
		</div>	
      {fields.interactiveImages ? (

			<InterActiveImages data={fields.interactiveImages} />

  		) : ''}
	</div>

      {fields.requirements ? (
        <section className="stand-requirements">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="logo">
                  <img src={fields.requirements.logo.sourceUrl} alt={fields.requirements.logo.altText} />
                </div>
                <div className="button-wrap">
                  <a href={fields.requirements.buttonUrl} className="button button-light-bg">{fields.requirements.button}</a>
                </div>
              </div>
              <div className="col">
                <div className="multi-title">
                  <h2>
                    {fields.requirements.title}
                    <strong>{fields.requirements.subtitle}</strong>
                  </h2>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: fields.requirements.content }}></div>
                <strong className="slogan" dangerouslySetInnerHTML={{ __html: fields.requirements.slogan }}></strong>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="button-wrap">
                  <a href={fields.requirements.buttonUrl2} className="button button-light">{fields.requirements.button2}</a>
                </div>
              </div>
              <div className="col table-wrap">
                <h3 dangerouslySetInnerHTML={{ __html: fields.requirements.tableTitle }}></h3>
                <table>
                  <tbody>
                    {fields.requirements.table.length > 0 && fields.requirements.table.map((row, index) => {
                      return (
                        <tr key={`row-${index}`}>
                          <td>{row.col1}</td>
                          <td>{row.col2}</td>
                          <td>{row.col3}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      ) : ''}
    </React.Fragment>
  );
}

export default StandDesigner
