import React, { useState, useContext, useEffect } from 'react'
import GoogleLogo from '../assets/svg/google_logo.svg'
import AppleLogo from '../assets/svg/apple_logo.svg'
import firebase from 'gatsby-plugin-firebase'
import useAuthState from '../functions/useAuthState'
// import { AuthContext } from '../context/auth'
import { navigate, Link } from 'gatsby'

const Login = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
    error: null
  })

  const [user, loading, error] = useAuthState(firebase)

  // const { setUser } = useContext(AuthContext)

  const handleChange = e => {
    const value = e.target.value
    setState({ 
      ...state,
      [e.target.name]: value
    })
  }

  useEffect(() => {
    if (user) {
      navigate('/my-account');
    }
  })

  const signInWithGoogle = async () => {
    try {
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      const user = await firebase.auth().signInWithPopup(provider);
      if (user) {
        // console.log(user)
        const userDoc = await firebase.firestore().collection('Users').doc(user.user.uid).get();
        if (!userDoc.exists) {
          const displayName = user.user.displayName.split(' ');
          const firstName = displayName[0] ? displayName[0] : '';
          const lastName = displayName[0] ? displayName[1] : '';
          const newUser = user && user.user && await firebase.firestore().collection('Users').doc(user.user.uid).set({
            email: user.user.email,
            firstName: firstName,
            lastName: lastName
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setState({ ...state, error: null })
    try {
      const response = await firebase.auth().signInWithEmailAndPassword(state.email, state.password)
      if (response.user.uid) {
        navigate('/my-account/')
      }
    } catch (err) {
        setState({ ...state, error: err.message })
    }
  }

  return (
    <React.Fragment>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="login-section">
              <div className="login-heading">
                <h4>Sign in to Your Account</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="cart-form-field">
                  <input
                    name="email" 
                    type="email" 
                    placeholder="Email*" 
                    value={state.email} 
                    onChange={handleChange}
                  />
                </div>
                <div className="cart-form-field">
                  <input
                    name="password" 
                    type="password"  
                    placeholder="Password*"
                    value={state.password} 
                    onChange={handleChange}
                  />
                </div>
                <div className="login-options">
                  <div className="checkbox rem-check">
                    <input type="checkbox" />
                    <label>Remember me</label>
                  </div>
                  <Link to="/forgot-password">Lost your password?</Link>
                </div>
                <div className="signin-container">
                  <button className="button button-dark">Sign In</button>
                </div>
                {state.error ? <p>{state.error}</p> :  null}
              </form>
            </div>
            <div className="row alt-login">
              <h4>New to Visual Forester?</h4>
              <p>Create an account and proceed to checkout.</p>
              <div className="button-container">
                <div className="row">
                  <Link to='/register' className="button button-dark">CREATE AN ACCOUNT</Link>
                </div>
                {/* <div className="row">
                  <button onClick={signInWithGoogle} className="button button-ggl"><img src={GoogleLogo}/>CONTINUE WITH GOOGLE</button>
                </div> */}
                {/* <div className="row">
                  <button className="button button-aapl"><img src={AppleLogo}/>CONTINUE WITH APPLE</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Login