import React from 'react'
import ReactPlayer from "react-player/lazy"
import NewsletterSignUp from "./NewsletterSignUp"

const VFPro = ({ fields }) => {
  return (
  // This is all temp while vf pro is being worked on, the rest of the backend for a more complicated page needs to be done (custom fields)
<div>
 <div className="show-desktop">
  <div className="promain">
  			<h1>
				Visual Forester Pro is coming soon
			</h1>
			
			<p>
				The next evolution of the platform is coming with Visual Forester Professional. The program was designed from the ground up to help you visualize and understand your forest on a grand scale, and it does not disappoint. With its intuitive interface and powerful tools, Visual Forester Pro will be the perfect tool for any professional looking to communicate and display harvests, treatments, reforestation and more.
			</p>
			<p>
				From creating detailed digital twins to developing comprehensive management plans, Visual Forester Professional has everything you need to communicate like never before. It is built on a foundation of everything we’ve learned from the last 25 years of forest management and application development. Be the first in line by joining the mailing list to get development updates and beta access with Visual Forester Pro. 
			</p>
    <div className="rows">
		<div className="col">

			<div className="list">
			<h1>Features:</h1>
<ul>
 	<li>up to 10,000 acres</li>
 	<li>32 unique tree species models</li>
 	<li>objects library including shrubs, ground vegetation, animals, and placemaking objects</li>
 	<li>trees from location using TreeMap dataset</li>
 	<li>DEM import for terrain</li>
 	<li>shapefile import</li>
 	<li>utilize single tree inventory derived from LiDAR</li>
 	<li>change over time</li>
 	<li>multiple harvest scenarios</li>
 	<li>road and path import</li>
 	<li>terrain painting</li>
</ul>
			</div>
		</div>
			  <div className="col2">
				<div className="player-wrapper">
                  {fields?.video? (
                    <ReactPlayer
                      url={fields?.video}
					  className='react-player' 
					  width='100%'
                      height='100%'
                      loop={false}
                      controls={true}
                    />
                  ) : (
                    ""
                  )}
				 </div>
			</div>
	</div>
	<div className="button-row">
		<div className="col">
			<h3>
				Want To Learn More?
			</h3>
				Sign up to our newsletter for the latest updates on Visual Forester Pro
		</div>
		<div className="col">
			<NewsletterSignUp color="light" />
		</div>
	</div>
</div>	
</div>

<div className="show-mobile">
  <div className="promain">
  <div className="row2">
  					<div className="player-wrapper">
                  {fields?.video? (
                    <ReactPlayer
                      url={fields?.video}
					  className='react-player' 
					  width='100%'
                      height='100%'
                      loop={false}
                      controls={true}
                    />
                  ) : (
                    ""
                  )}
			</div>
			</div>
    <div className="rows">
		<div className="col">
			<h1>
				Visual Forester Pro is coming soon
		</h1>
			
			<p>
				The next evolution of the platform is coming with Visual Forester Professional. The program was designed from the ground up to help you visualize and understand your forest on a grand scale, and it does not disappoint. With its intuitive interface and powerful tools, Visual Forester Pro will be the perfect tool for any professional looking to communicate and display harvests, treatments, reforestation and more.
			</p>
			<p>	
				From creating detailed digital twins to developing comprehensive management plans, Visual Forester Professional has everything you need to communicate like never before. It is built on a foundation of everything we’ve learned from the last 25 years of forest management and application development. Be the first in line by joining the mailing list to get development updates and beta access with Visual Forester Pro. 
			</p>
			<div className="list">
			<h1>Features:</h1>
				<ul>
 	<li>up to 10,000 acres</li>
 	<li>32 unique tree species models</li>
 	<li>objects library including shrubs, ground vegetation, animals, and placemaking objects</li>
 	<li>trees from location using TreeMap dataset</li>
 	<li>DEM import for terrain</li>
 	<li>shapefile import</li>
 	<li>utilize single tree inventory derived from LiDAR</li>
 	<li>change over time</li>
 	<li>multiple harvest scenarios</li>
 	<li>road and path import</li>
 	<li>terrain painting</li>
</ul>
			</div>
		</div>
	</div>
	<div className="button-row">
		<div className="col">
			<h3>
				Want To Learn More?
			</h3>
				Sign up to our newsletter for the latest updates on Visual Forester Pro
		</div>
		<div className="col">
			<NewsletterSignUp color="light" />
		</div>
	</div>
</div>	
</div>


</div>	 
  );
}

export default VFPro
