import React from "react"
import { Link } from "gatsby"
import EditSvg from "../../assets/images/edit.svg"
import BasketSvg from "../../assets/images/basket.svg"
const host = process.env.WP_BASE_URL
const ListCompare = ({ folders, className, removeFolder, editFolder }) => {
  return (
    <ul className={className}>
      <li className={"list-compare"}>
        <span>LABEL</span>
        <span>VIEWER URL</span>
        <div className={"date-wrapper"}>DATE</div>
      </li>
      {folders.map((item) => {
        return (
          <li key={item.folder} className={"list-compare"}>
            <span>{item?.metaData?.label}</span>
            <Link to={`/image-compare/${item.folder}`}>{`${host.replace(
              "api.",
              "https://"
            )}/image-compare/${item.folder}`}</Link>
            <div className={"date-wrapper"}>
              <span>{item?.metaData?.date}</span>
              <div className={"buttons-group"}>
                <span
                  onClick={() => {
                    editFolder(item.folder)
                  }}
                >
                  <img src={EditSvg} alt="edit" />
                </span>
                <span
                  onClick={() => {
                    removeFolder(item.folder)
                  }}
                >
                  <img src={BasketSvg} alt="basket" />
                </span>
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default ListCompare
